import React, {
  Component,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { awsUrl2, awsUrl, awsUrl3 } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import {
  TableBody,
  TablePagination,
  TextField as TField,
} from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import toast, { Toaster } from "react-hot-toast";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import { Auth } from "aws-amplify";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Modal } from "react-bootstrap";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import LoadingOverlay from "react-loading-overlay";
import Popup from "../../component/common/Popup";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { FaRegHandshake } from "react-icons/fa6";
import { CiLinkedin } from "react-icons/ci";
// import TextField from "../common/TextField.jsx";
import { isDeepEquals } from "../../utils/isDeepEquals.js";
import UpdateTextField from "../common/UpdateTextField.jsx";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import DealClosedLostButtons from "../subcompo/DealClosedLostButtons";
import ConfirmDealButton from "../subcompo/ConfirmDealButton";

import { createCommentForDownload } from "../common/dashboard_helper.js";

import "./DashboardAdmin.scss";
import { MdEdit } from "react-icons/md";
import NumberFormat from "react-number-format";

let ismobile = window.innerWidth < 1600;

const {
  DownloadDashboardFiles,
} = require("../subcompo/DownloadDashboardFiles.jsx");

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const statusNameMapping = {
  "Opened Email": "Opened Email",
  "Quote Generated": "Initial Pricing",
  "UW Questions": "Locations and Payroll",
  "Company Profile": "Company Information",
};
const statusPriorityMap = {
  Submitted: 1,
  "User Submitted": 2,
  "Quote Generated": 3,
  "Uploaded Document": 4,
  "UW Questions": 5,
  "Opened Email": 90,
  SQL: 7,
  //"Company Profile(Get Quote)": 8,
  //"Company Profile": 9,
  "Company Information": 9,
  "Initial Pricing": 3,
  "Locations and Payroll": 5,
  "Landing Page": 10,
  "Visited Website": 11,
  "Affinity Logo Clicked": 12,
  "Fourth Logo Clicked": 13,
  "Clicked on Affinity logo": 14,
  "Clicked on Fourth logo": 15,
  "Clicked on the image": 16,
  "Clicked on insure comp logo": 17,
  "Watched Video": 18,
  "Visited LinkedIn": 19,
  "Visited Facebook": 20,
  "Visited Instagram": 21,
  "Accessed Social Media": 22,
  //  MQL: 23,
  "Not Campaigned": 24,
  "-": 999,
};
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let selectedRows = [];
let initialcomment = {
  comment: "",
  commentTimestamp: "",
  email: "",
};

function Tab2DetailPanel(props) {
  const {
    rowData,
    parentAdditionalRows,
    showAddContact,
    prim_key,
    saveContact,
  } = props;
  const prev_props = usePrevious({
    rowData,
    parentAdditionalRows,
    showAddContact,
  });
  const [additionalRows, setAdditionalRows] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  // const [saveClicked, setSaveClicked] = useState(false);

  const [additionalRowId, setAdditionalRowId] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [firstAdd, setFirstAdd] = useState(false);
  const [popupData, setPopupData] = useState({
    title: "",
    children: <></>,
    acceptBtn: "",
    acceptBtnCallback: () => {},
    rejectBtnCallback: () => {},
    show: false,
  });
  const [_rowData, _setRowData] = useState(rowData);
  const [_rowData1, _setRowData1] = useState(rowData);

  console.log("rowData=>>", rowData);
  const EMPTY_ROW_PANEL = {
    contact_name: "",
    phone_number: "",
    email: "",
    job_description: "",
    visit_count: 0,
    status: "-",
    isSaved: false,
    additionalRowKey: additionalRowId,
  };

  useEffect(() => {
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));

    if (
      (parentAdditionalRows?.length && !_additionalRows?.length) ||
      (parentAdditionalRows?.length ===
        prev_props?.parentAdditionalRows?.length &&
        !isDeepEquals(parentAdditionalRows, prev_props?.parentAdditionalRows))
    ) {
      setAdditionalRows(parentAdditionalRows);
    }
  }, [parentAdditionalRows]);

  const onAddContact = () => {
    if (!firstAdd) {
      setFirstAdd(true);
    }
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    _additionalRows.push(JSON.parse(JSON.stringify(EMPTY_ROW_PANEL)));
    setAdditionalRowId((prev) => prev + 1);
    setAdditionalRows(_additionalRows);
  };
  const onDeleteContact = (key) => {
    const newRows = additionalRows.filter(
      (item) => item.additionalRowKey !== key
    );
    setAdditionalRows(newRows);
  };
  const handleEditClick = (element, email) => {
    // _rowData.email_specific
    // console.log("element=>", element, _rowData);
    const tempRowData = JSON.parse(JSON.stringify(_rowData));
    const tempRowData2 = JSON.parse(JSON.stringify(_rowData));
    const tempVar = tempRowData?.email_specific?.[email]?.companyProfile;
    // console.log("iiiiii", Object.keys(tempVar), tempVar);

    for (let i of Object.keys(tempVar)) {
      // console.log("iiiiii", i, tempVar[i]);
      const tempObj = JSON.parse(JSON.stringify(tempVar[i]));
      tempVar[i] = tempObj.value;
    }

    if (!tempRowData2?.email_specific?.[email]?.isEdit) {
      tempRowData2.email_specific[email].isEdit = true;

      setFirstAdd(true);
      setEditedRows((prev) => [
        ...prev,
        { ...tempRowData?.email_specific?.[email]?.companyProfile },
      ]);
    }
    _setRowData(tempRowData2);
    // _rowData.email_specific;
  };

  const handleEditValueChange = (value, email, field) => {
    _setRowData1((prevState) => {
      // Create a copy of the previous state
      const newState = { ...prevState };
      console.log("newState", newState);
      // Loop through all keys in the state object
      // for (const key in newState.email_specific) {
      // if (newState.email_specific[key].isEdit === true) {
      newState.email_specific[email]["companyProfile"][field].value = value;
      // }
      // }

      return newState;
    });
    setEditedRows((prev) => {
      // Make a shallow copy of the array
      const newRows = [...prev];
      // console.log("prev=>", prev);
      // Find the index of the item to update
      const index = newRows.findIndex((item) => item?.emailId === email);
      // If the item is found, update the specific field
      // console.log("index=>", index);
      if (index !== -1) {
        newRows[index] = {
          ...newRows[index], // Make a shallow copy of the item
          [field]: value, // Update the specific field
        };
      }
      // console.log("newRows", newRows);
      return newRows;
    });
  };
  const checkAndUpdateAll = () => {
    _setRowData((prevState) => {
      // Create a copy of the previous state
      const newState = { ...prevState };

      // Loop through all keys in the state object
      for (const key in newState.email_specific) {
        if (newState.email_specific[key].isEdit === true) {
          newState.email_specific[key].isEdit = false;
        }
      }

      return newState;
    });
  };

  console.log("additional rows", editedRows); // Log previous state for debugging

  const onChangeValue = (key, val, indx, error) => {
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    _additionalRows[indx][key] = val;

    if (error) _additionalRows[indx][`${key}_error`] = true;
    else _additionalRows[indx][`${key}_error`] = false;

    setAdditionalRows(_additionalRows);
  };

  const validateEmail = (email) => {
    //check if email address is valid
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = reg.test(email);
    if (!isValid) return false;

    //check if email already exists in email_specific
    let existing_emails = _rowData.email_specific
      ? Object.keys(_rowData.email_specific)
      : [];
    if (existing_emails.includes(email)) return false;

    //check if email already exists in additional_rows
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    for (let _row of _additionalRows) if (_row.email === email) return false;

    return true;
  };
  console.log("_rowData_rowData_rowData", _rowData);

  const validateData = () => {
    console.log("parentData", _rowData, Object.keys(_rowData.email_specific));

    let hasIncompleteRow = additionalRows.some(
      (row) =>
        !(
          (
            row.contact_name &&
            // row.phone_number &&
            row.email
          )
          // row.job_description
        )
    );

    let invalidEmail = additionalRows.some((row) => row.email_error);
    let invalidPhone = additionalRows.some((row) => row.phone_number_error);

    if (hasIncompleteRow) {
      return { msg: "Please enter all fields", valid: false };
    }
    console.log("invalidEmail", invalidEmail);
    if (invalidEmail) {
      return { msg: "Please enter valid email id", valid: false };
    }
    if (invalidPhone) {
      return { msg: "Please enter valid phone number", valid: false };
    }

    return { msg: "", valid: true };
  };
  const updatePopUpData = (pData) => {
    setPopupData(pData);
  };
  return (
    <div style={{ padding: "25px" }}>
      <TableContainer component={Paper}>
        <Table size="small" className="sub_tab2">
          <TableHead
            style={{
              background: "#00000066",
              color: "whitesmoke",
            }}
          >
            <TableRow>
              <TableCell>Contact Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Job Description</TableCell>
              <TableCell>Visit Count</TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>Campaign Name</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_rowData.email_specific ? (
              Object.entries(_rowData.email_specific).map(
                ([email, element], index) => {
                  let step;
                  if (element.companyProfile) {
                    if (element.isUserSubmitted) {
                      step = "User Submitted";
                    } else if (Number(element.proposalGeneratedCount) > 0) {
                      step = "Proposal Generated";
                    } else if (element.quote_status) {
                      if (element.quote_status === "uwportal") {
                        step = "UW Portal";
                      } else if (element.quote_status === "proceed_uw") {
                        step = "Approved";
                      } else if (element.quote_status === "reject") {
                        step = "Rejected";
                      }
                    } else if (Number(element.quoteGeneratedCount) > 0) {
                      step = "Initial Pricing";
                    } else if (element.formStage) {
                      if (element.formStage === "two") {
                        if (element.nonEligibilityList) {
                          step = "Not Eligible";
                        } else {
                          step = "Locations and Payroll";
                        }
                      } else if (element.formStage === "one") {
                        step = "Company Information";
                      }
                    } else if (
                      element.visitCount &&
                      Number(element.visitCount) > 0
                    ) {
                      step = "Landing Page";
                    } else {
                      step = "-";
                    }
                    if (
                      element.companyProfile.status &&
                      element.companyProfile.status.value
                    ) {
                      step =
                        statusPriorityMap[element.companyProfile.status.value] <
                        statusPriorityMap[step]
                          ? statusNameMapping[
                              element?.companyProfile?.status?.value || ""
                            ] || element.companyProfile.status.value
                          : step;
                    }
                    const campaignMapEntries = Object.entries(
                      element.campaignMap || {}
                    );
                    const lastCampaignEntry =
                      campaignMapEntries.length > 0
                        ? campaignMapEntries[campaignMapEntries.length - 1]
                        : [null, ""];
                    const lastCampaignName = lastCampaignEntry[1];
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">
                          {element?.isEdit ? (
                            <TField
                              name="contact_name"
                              defaultValue={
                                element.companyProfile &&
                                element.companyProfile.contact_name
                                  ? element.companyProfile.contact_name.value
                                  : ""
                              }
                              // onChange={(e) =>
                              //   handleEditValueChange(e.target.value, email, "contact_name")
                              // }
                              onBlur={(e) =>
                                handleEditValueChange(
                                  e.target.value,
                                  email,
                                  "contact_name"
                                )
                              }
                            />
                          ) : (
                            <>
                              {element.companyProfile &&
                              element.companyProfile.contact_name
                                ? element.companyProfile.contact_name.value
                                : ""}
                            </>
                          )}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.isEdit ? (
                            <TField
                              name="phoneNumber"
                              defaultValue={
                                element?.companyProfile?.phoneNumber?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                element?.companyProfile?.contact_number?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                ""
                              }
                              // onChange={(e) =>
                              //   handleEditValueChange(e.target.value, email, "phoneNumber")
                              // }
                              onBlur={(e) =>
                                handleEditValueChange(
                                  e.target.value,
                                  email,
                                  "phoneNumber"
                                )
                              }
                            />
                          ) : (
                            <>
                              {element?.companyProfile?.phoneNumber?.value?.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                "($1) $2-$3"
                              ) ||
                                element?.companyProfile?.contact_number?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                ""}
                            </>
                          )}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.emailId?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.isEdit ? (
                            <TField
                              name="job_title"
                              defaultValue={
                                element?.companyProfile?.job_title?.value
                              }
                              // onChange={(e) =>
                              //   handleEditValueChange(e.target.value, email, "job_title")
                              // }
                              onBlur={(e) =>
                                handleEditValueChange(
                                  e.target.value,
                                  email,
                                  "job_title"
                                )
                              }
                            />
                          ) : (
                            <>{element?.companyProfile?.job_title?.value}</>
                          )}
                        </TableCell>
                        <TableCell scope="row">
                          {element.visitCount || 0}
                        </TableCell>
                        <TableCell scope="row">{step}</TableCell>
                        {/* <TableCell scope="row">
                          {lastCampaignName
                            ? lastCampaignName
                            : _rowData.latestCampaign}
                        </TableCell> */}
                        {/* <TableCell scope="row">-</TableCell> */}
                        <TableCell>
                          <button
                            className="button-delete-row"
                            onClick={() => {
                              // onDeleteContact(ele?.additionalRowKey);

                              handleEditClick(element, email);
                            }}
                          >
                            <MdEdit />
                          </button>
                          -
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
              )
            ) : (
              <TableRow key={0}>
                <TableCell scope="row">{_rowData.contactName}</TableCell>
                <TableCell scope="row">
                  {_rowData.phoneNumber
                    ? _rowData.phoneNumber.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      )
                    : "-"}
                </TableCell>
                <TableCell scope="row">{_rowData.userEmail}</TableCell>
                <TableCell scope="row">{""}</TableCell>
                <TableCell scope="row">{_rowData.visitCount}</TableCell>
                <TableCell scope="row">{_rowData.step || ""}</TableCell>
                <TableCell scope="row">{_rowData.campaignMap}</TableCell>
                <TableCell scope="row">-</TableCell>
              </TableRow>
            )}

            {additionalRows?.map((ele, indx) => {
              return (
                <TableRow key={ele.additionalRowKey}>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.contact_name
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "contact_name",
                            e.target.value?.trim(),
                            indx
                          )
                        }
                        defaultValue={ele.contact_name}
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.phone_number
                    ) : (
                      <NumberFormat
                        defaultValue={ele.phone_number}
                        onChange={(e) => {
                          const value = e.target.value?.replace(/[^\d]/g, ""); // Remove non-digit characters
                          if (value && value.length === 10) {
                            onChangeValue(
                              "phone_number",
                              e.target.value?.trim(),
                              indx,
                              false
                            );
                          } else {
                            onChangeValue(
                              "phone_number",
                              e.target.value?.trim(),
                              indx,
                              true
                            );
                          }
                        }}
                        placeholder="(000) 000-0000"
                        autoComplete="off"
                        id="phoneNumber"
                        format="(###) ###-####"
                        type="text"
                        style={
                          ele.phone_number_error
                            ? {
                                border: "none",
                                borderBottom: "1px solid red",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                            : {
                                border: "none",
                                borderBottom: "1px solid #1890ff",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.email
                    ) : (
                      <input
                        type="text"
                        onChange={(e) => {
                          let val = e.target.value.trim();
                          if (val && validateEmail(val)) {
                            onChangeValue("email", val, indx, false);
                          } else {
                            onChangeValue("email", val, indx, true);
                          }
                        }}
                        defaultValue={ele.email}
                        style={
                          ele.email_error
                            ? {
                                border: "none",
                                borderBottom: "1px solid red",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                            : {
                                border: "none",
                                borderBottom: "1px solid #1890ff",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                        }
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.job_description
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "job_description",
                            e.target.value.trim(),
                            indx
                          )
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.job_description}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {true ? (
                      ele.visit_count
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "visit_count",
                            e.target.value.trim(),
                            indx
                          )
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.visit_count}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {true ? (
                      ele.status
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue("status", e.target.value.trim(), indx)
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.status}
                      ></input>
                    )}
                  </TableCell>
                  {/* <TableCell>-</TableCell> */}
                  <TableCell>
                    {ele.isSaved ? (
                      <>-</>
                    ) : (
                      <button
                        className="button-delete-row"
                        onClick={() => {
                          onDeleteContact(ele?.additionalRowKey);
                        }}
                      >
                        <img
                          style={{ height: "16px" }}
                          src={require("../../images/delete4.png")}
                        />
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {showAddContact && (
              <TableRow key={1000}>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {errMsg && <div style={{ color: "red" }}>{errMsg}</div>}

                  <button
                    className="btn mx-2 button-save-style"
                    onClick={(e) => onAddContact()}
                  >
                    {/* <img
                      style={{ height: "16px" }}
                      src={require("../../images/plus4.png")}
                    /> */}
                    Add Contact
                  </button>

                  {firstAdd && (
                    <>
                      <button
                        className="btn button-save-style"
                        onClick={(e) => {
                          if (
                            validateData().valid ||
                            additionalRows.length === 0
                          ) {
                            // checkAndUpdateAll();
                            // setSaveClicked(true);
                            setErrMsg("");
                            updatePopUpData({
                              show: true,
                              title: firstAdd
                                ? "Contacts once added cannot be deleted. Would you like to continue?"
                                : "Would you like to continue?",
                              //"Would you like to continue"
                              acceptBtn: "Yes",
                              rejectBtn: "No",
                              acceptBtnCallback: () => {
                                _setRowData(_rowData1);

                                saveContact(
                                  prim_key,
                                  additionalRows,
                                  editedRows,
                                  rowData?.fromUserTracking
                                );
                                setFirstAdd(false);
                              },
                              rejectBtnCallback: () => {
                                checkAndUpdateAll();
                              },
                            });
                          } else {
                            // _setRowData(_rowData);

                            setErrMsg(validateData().msg);
                          }
                        }}
                      >
                        Save
                      </button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {popupData.show && (
        <Popup
          dashboardstyle={true}
          popupData={popupData}
          updatePopUpData={updatePopUpData}
        />
      )}
    </div>
  );
}
class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: true,
      program: "PEO",
      source: "Affinity",
      style: {},
      uploadDateFlag: false,
      sourceSelected: "peo",
      confirmAllLoading: false,
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        rejectBtnCallback: () => {},
        show: false,
      },
      modalBody: [],
      openModal: false,
      scrollLeft: false,
      scrollRight: true,
      adminHistoryComment: {},
      engagedCommentHistory: {},
      campaignedCommentsHistory: {},
      globalAddContact: {},
    };
    this.handleDisplayHide = this.handleDisplayHide.bind(this);
    console.log(this.props);
  }

  fetchDashboardData = () => {
    console.log("tabNAME===", this.props.tabName);
    if (this.props.tabName === "tab3") {
      this.updateDisplayTab3();
    } else if (this.props.tabName === "tab1") {
      this.updateDisplayTab1();
    } else if (this.props.tabName === "tab2") {
      this.updateDisplayTab2();
    }
  };

  updatePopUpData = (popUp) => {
    this.setState({ popupData: popUp });
  };

  saveContact = async (key, add_rows, edit_rows, fromUserTracking) => {
    const rows = add_rows.concat(edit_rows);
    console.log("key=>", key, add_rows, edit_rows, "rows=>", rows);
    this.setState((prevState) => {
      let _globalAddContact = JSON.parse(
        JSON.stringify(prevState.globalAddContact)
      );
      axios
        .post(
          awsUrl + "/api/addContactAdmin",
          JSON.stringify({
            domain: key,
            rows: rows,
            tab: this.props.tabName,
            fromUserTracking,
          })
        )
        .then((res) => {
          //api call to save email in email_specific and domain_mapping
          for (let row of add_rows) {
            if (!_globalAddContact[key]) _globalAddContact[key] = [];
            row.isSaved = true;
            _globalAddContact[key].push(row);
          }
          this.setState({ globalAddContact: _globalAddContact });
        })
        .catch((err) => console.log(err));
    });
  };

  getsalespersonName = (userData) => {
    let name = "";
    if (userData?.salespersonName) {
      name = userData?.salespersonName;
    } else if (userData?.default_saleperson) {
      name = userData?.default_saleperson;
    } else if (userData?.default_saleperson && userData?.salespersonName) {
      name = userData?.salespersonName;
    } else {
      name = userData?.salespersonName;
    }
    return name;
  };

  handleSelection = (rows) => {
    selectedRows = rows;
    console.log(selectedRows.length);
  };

  handleConfirmAll = (tabName) => {
    let { dealMap } = this.props;
    if (selectedRows.length > 0) {
      this.updatePopUpData({
        show: true,
        title: "Do you wish to confirm the deals?",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              Once confirmed, cannot be reverted.
            </span>
          </>
        ),
        acceptBtn: "Yes",
        acceptBtnCallback: async () => {
          try {
            let promiseList = [];
            let confirmAllDataList = [];
            let i = 1;
            this.setState({ confirmAllLoading: true });
            for (let row of selectedRows) {
              let key = row.domain || row.salesID;

              let dealFlag;
              if (dealMap[key] === "true") {
                dealFlag = "closed";
              } else if (dealMap[key] === "false") {
                dealFlag = "lost";
              } else {
                dealFlag = row.dealFlag;
              }

              if (
                (row.dealFlag === "lost" ||
                  row.dealFlag === "closed" ||
                  dealMap[key] === "true" ||
                  dealMap[key] === "false") &&
                (!row.dealConfirmed || row?.dealConfirmed !== "true")
              ) {
                let api_body = {
                  salesId: row?.recordDetails?.salesId || "",
                  domain: row?.recordDetails?.domain || "",
                  userId: row?.recordDetails?.userId || "",
                  isMailCmpn: row?.recordDetails?.isMailCmpn || false,
                  uuidList: row?.recordDetails?.uuidList || [],
                  confirmDeal: "true",
                };

                confirmAllDataList.push({
                  tab: tabName,
                  index: Number(row.tableData.id),
                  dealFlag,
                  rowData: row,
                  key:
                    row?.recordDetails?.domain || row?.recordDetails?.salesId,
                  confirmDeal: "true",
                });

                promiseList.push(
                  axios
                    .post(
                      awsUrl + "/api/setDealConfirmed",
                      JSON.stringify(api_body)
                    )
                    .catch((err) => console.log(err))
                );

                if (i % 10 === 0) {
                  await Promise.all(promiseList);
                  promiseList = [];
                }
                i++;
              }
            }

            if (promiseList.length > 0) {
              await Promise.all(promiseList);
            }

            this.setState({ confirmAllLoading: false });
            this.props.handleDealConfirmAll(confirmAllDataList);
            for (let data of confirmAllDataList) {
              this.props.updateConfirmDealMap(data.key, data.confirmDeal);
            }
          } catch (error) {
            console.log("error: ", error);
          }
        },
      });
    } else {
      this.updatePopUpData({
        show: true,
        title: "Nothing to confirm.",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              Please select rows to confirm deals.
            </span>
          </>
        ),
        acceptBtn: "Okay",
        acceptBtnCallback: async () => {},
        disableNoButton: true,
      });
    }
  };

  handleDisplayHide = () => {
    let { tabName, selected } = this.props;
    this.setState({
      style: tabName !== selected ? { display: "none", fontSize: "12px" } : {},
    });
  };

  getUploadDateFlag = async () => {
    const currentAuthUser = await Auth.currentAuthenticatedUser();
    if (currentAuthUser) {
      let groups =
        currentAuthUser?.signInUserSession?.accessToken?.payload[
          "cognito:groups"
        ];
      if (groups.includes(process.env.REACT_APP_DATA_TEAM)) return true;
      else return false;
    }
  };

  updateUploadFlag = async () => {
    let flag = await this.getUploadDateFlag();
    this.setState({ uploadDateFlag: flag });
  };

  componentDidMount() {
    this.fetchDashboardData();
    this.updateUploadFlag();
    window.addEventListener("load", this.handleDisplayHide);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.handleDisplayHide();
    }
  }

  updateDomainMessageidFiles = (domain, key, val) => {
    let { domain_messageid_files } = JSON.parse(JSON.stringify(this.state));
    domain_messageid_files[domain][key] = val;
    this.setState({ domain_messageid_files });
  };

  appendRemaining = (str) => {
    let len = str.length;
    for (let i = 1; i <= 10 - len; i++) {
      str = "0" + str;
    }
    return str;
  };

  //Function to Update tab1 , tab2, tab3 data
  updateAdminComment = (data, rowData) => {
    let { rowDataList } = JSON.parse(JSON.stringify(this.props));
    let index = rowData.tableData.id;
    let requestBody = {};

    requestBody.domain = data.id ? data.id : data.domain;

    requestBody.admincomment = data.admincomment;
    if (this.props.tabName === "tab2") {
      if (rowDataList[index].tableName === "userTrackingTable")
        requestBody.tableName = "tab1";
      else requestBody.tableName = "tab2";
    } else {
      requestBody.tableName = this.props.tabName;
    }

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {
        console.log("Data saved", data);
      })
      .catch((error) => {
        console.log("error in posting comment", error);
      });
  };

  //Function to update won and lost tabs
  updateAdminWonLossComment = (data, rowData) => {
    let requestBody = {};
    requestBody.domain = data.id ? data.id : data.domain;
    requestBody.admincomment = data.admincomment;
    requestBody.tableName = rowData.tab;
    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {
        console.log("Data saved", data);
      })
      .catch((error) => {
        console.log("error in posting comment", error);
      });
  };

  updateComment = (e, rowData) => {
    let { program, source } = JSON.parse(JSON.stringify(this.state));
    let { rowDataList } = JSON.parse(JSON.stringify(this.props));
    let index = rowData.tableData.id;
    let requestBody = {};

    requestBody.domain = rowDataList[index].domain;
    requestBody.comment = e.target.value;
    if (this.props.tabName === "tab2") {
      if (rowDataList[index].tableName === "campaignTable")
        requestBody.tableName = "tab1";
      else requestBody.tableName = "tab2";
    } else {
      requestBody.tableName = this.props.tabName;
    }

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateDiscountData = (rowData) => {
    let requestBody = {
      user_email_id: rowData.userEmail,
      uuid_carrier: rowData.uuid_carrier,
      discountData: rowData.discountData,
    };
    axios
      .post(awsUrl2 + "/api/updateUserStatusTable", requestBody)
      .then((res) => {})
      .catch((err) => console.log("error: ", err));
  };

  camelCase = (str) => {
    if (str)
      return str
        .trim()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");
    return "";
  };

  formatFein = (value) => {
    let fein;
    value = value.split("-").join("");
    var len = value.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value.slice(2);
    }

    return fein;
  };

  onClickCompanyName = (rowData) => {
    sessionStorage.clear();
    if (rowData.currProspectDetails) {
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(rowData.currProspectDetails)
      );
    }
    sessionStorage.setItem("hideButtons", "true");
    if (rowData.salesID) {
      sessionStorage.setItem("salesID", rowData.salesID);
    }
    if (rowData.formStage) {
      if (rowData.formStage !== "one" && rowData.formStage !== "two")
        sessionStorage.setItem("formStage", "three");
      else sessionStorage.setItem("formStage", rowData.formStage);
    }
    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }
    if (rowData.address) {
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
    }
    if (rowData.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
    }
    if (rowData.sortKeyList) {
      sessionStorage.setItem(
        "sortKeyList",
        JSON.stringify(rowData.sortKeyList)
      );
    }
    sessionStorage.setItem("showAllFields", true);
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YY - hh:mm A");
    let momentB = moment(b[field], "M/D/YY - hh:mm A");
    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else if (
      (!momentA.isValid() && !momentB.isValid()) ||
      momentA.isSame(momentB)
    ) {
      return 0;
    }
  };

  formatInCamel = (str) => {
    if (str) {
      return str[0].toUpperCase() + "" + str.slice(1);
    }
    return str;
  };

  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.92rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.9rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.88rem";
    }
    if (window.innerWidth < 1300) {
      return "0.85rem";
    }
  };

  customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };
  styles = (step, rowData) => {
    let style = {
      width: "80%",
      borderRadius: "20px",
      padding: "5px",
      textAlign: "center",
    };
    if (step === "Submitted") {
      style.backgroundColor = "#5ADA38";
    } else if (step === "User Submitted") {
      style.backgroundColor = "#5ADA38";
    } else if (step === "Proposal Generated") {
      style.backgroundColor = "#5ADA38";
    } else if (step === "UW Portal") {
      style.backgroundColor = "#ffd737ac";
    } else if (step === "Approved") {
      style.backgroundColor = "#87FA4D";
    } else if (step === "Rejected") {
      style.backgroundColor = "#fa6d3e";
    } else if (step === "Company Information") {
      style.backgroundColor = "red";
    } else if (step === "MQL") {
      style.backgroundColor = "gray";
    } else if (step === "Initial Pricing") {
      style.backgroundColor = "#2ea44f";
    } else if (step === "Locations and Payroll") {
      style.backgroundColor = "#FFD737";
    } else if (Number(rowData.visitCount) > 0) {
      style.backgroundColor = "#FFD737";
    } else {
      style.backgroundColor = "#FFF";
    }
    return style;
  };
  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false, scrollRight: true });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };
  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft > maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };

  // tab3DetailPanel = (rowData) => {
  //   return (
  //     <div style={{ padding: "25px" }}>
  //       <TableContainer component={Paper}>
  //         <Table size="small" className="sub_tab3">
  //           <TableHead
  //             style={{
  //               background: "#00000066",
  //               color: "whitesmoke",
  //             }}
  //           >
  //             <TableRow>
  //               <TableCell>Contact Name</TableCell>
  //               <TableCell>Phone Number</TableCell>
  //               <TableCell>Email</TableCell>
  //               <TableCell>Job Description</TableCell>
  //               <TableCell>Visit Count</TableCell>
  //               <TableCell>Status</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {
  //               <TableRow key={0}>
  //                 <TableCell scope="row">
  //                   {(this.formatInCamel(
  //                     rowData?.companyProfile?.firstName?.value
  //                   ) || "") +
  //                     " " +
  //                     (this.formatInCamel(
  //                       rowData?.companyProfile?.lastName?.value
  //                     ) || "")}
  //                 </TableCell>
  //                 <TableCell scope="row">
  //                   {rowData?.companyProfile?.phoneNumber?.value?.replace(
  //                     /(\d{3})(\d{3})(\d{4})/,
  //                     "($1) $2-$3"
  //                   ) || "-"}
  //                 </TableCell>
  //                 <TableCell scope="row">{"-"}</TableCell>
  //                 <TableCell scope="row">{"-"}</TableCell>
  //                 <TableCell scope="row">{"0"}</TableCell>
  //                 <TableCell scope="row">{rowData.step || ""}</TableCell>
  //               </TableRow>
  //             }
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </div>
  //   );
  // };

  //function to update Admin modalbody state to display comment history modal
  handleAdminCommentsButtonClick = (comments, submittedDate, id) => {
    if (typeof comments?.[0] === "string" && comments?.[0] !== "") {
      let updatedComment = [
        {
          ...initialcomment,
          comment: comments?.[0],
          commentTimestamp: submittedDate,
        },
      ];
      this.setState({ modalBody: updatedComment });
    } else if (typeof comments?.[0] === "object") {
      this.setState({ modalBody: comments });
    } else {
      this.setState({ modalBody: [initialcomment] });
    }

    this.setState({ openModal: true });
  };

  //function to update others disable comments modalbody state to display comment history modal
  handleCommentHistory = (rowdata, rowkey, name) => {
    if (name === "salescomment") {
      rowdata.forEach((item) => {
        if (item.salesID === rowkey) {
          if (typeof item.salescomment?.[0] === "object") {
            this.setState({ modalBody: item.salescomment });
          } else if (typeof item.salescomment?.[0] === "string") {
            let updatedComment = [
              {
                ...initialcomment,
                comment: item.salescomment?.[0],
                commentTimestamp: item.uploadTimestamp,
              },
            ];
            this.setState({ modalBody: updatedComment });
          } else {
            this.setState({ modalBody: [initialcomment] });
          }
        }
      });
    } else if (name === "smcomment") {
      rowdata.forEach((item) => {
        if (item.salesID === rowkey) {
          if (typeof item.smcomment?.[0] === "object") {
            this.setState({ modalBody: item.smcomment });
          } else if (typeof item.smcomment?.[0] === "string") {
            let updatedComment = [
              {
                ...initialcomment,
                comment: item.smcomment?.[0],
                commentTimestamp: item.uploadTimestamp,
              },
            ];
            this.setState({ modalBody: updatedComment });
          } else {
            this.setState({ modalBody: [initialcomment] });
          }
        }
      });
    } else if (name === "comment") {
      rowdata.forEach((item) => {
        if (item.salesID === rowkey) {
          if (typeof item.comment?.[0] === "object") {
            this.setState({ modalBody: item.comment });
          } else if (typeof item.comment?.[0] === "string") {
            let updatedComment = [
              {
                ...initialcomment,
                comment: item.comment?.[0],
                commentTimestamp: item.uploadTimestamp,
              },
            ];
            this.setState({ modalBody: updatedComment });
          } else {
            this.setState({ modalBody: [initialcomment] });
          }
        }
      });
    }
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  setCommentBoxColor = (comments) => {
    if (comments?.[0] !== "") {
      if (typeof comments?.[0] === "string") {
        return "#FFE7C1";
      }
    }
    if (comments?.length > 1) {
      return "#FFE7C1";
    }
  };

  handleButtonClick = (domain) => {
    axios
      .post(awsUrl2 + `/api/moveCampaignToTracking`, { domain })
      .then((res) => {
        if (res.data === "Updated successfully") {
          axios
            .post(
              awsUrl2 + "/api/updateEngagedWithEmailCampaign/" + domain,
              JSON.stringify(domain)
            )
            .catch((err) => {
              toast.error("Error in API", {
                duration: 3000,
              });
              console.error(err);
            });
        }

        let paramsToSend = {
          domain,
          sendtosalesperson: "true",
        };

        axios
          .post(awsUrl2 + `/api/preUwData/updateTrackingData`, paramsToSend)
          .then((res) => {
            toast.success("Sent to SalesPerson", {
              duration: 3000,
            });
          })
          .catch((error) => {
            toast.error("Error in API", {
              duration: 3000,
            });
            console.log("error in posting data to user tracking", error);
          });
      })
      .catch((error) => {
        toast.error("Error in API", {
          duration: 3000,
        });
        console.log(error);
      });
  };

  updateEmod = (dataToSave) => {
    let requestBody = {};

    requestBody.domain = dataToSave.domain || "";
    requestBody.emod = dataToSave.emod;
    requestBody.tableName = "tab1";
    requestBody.isEmod = true;
    axios
      .post(awsUrl3 + "/api/updateEmodSff", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateSFF = (dataToSave) => {
    let requestBody = {};

    requestBody.domain = dataToSave.domain || "";
    requestBody.sff = dataToSave.sff;
    requestBody.tableName = "tab1";
    requestBody.isSFF = true;
    axios
      .post(awsUrl3 + "/api/updateEmodSff", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  handleCampaignDateButtonClick = (domain, event) => {
    let campaigndateValue = event.target.value;
    let paramsToSend = {
      domain,
      campaignDate: campaigndateValue,
    };

    if (campaigndateValue) {
      debugger;
      axios
        .post(awsUrl2 + `/api/preUwData/updateTrackingData`, paramsToSend)
        .then((res) => {
          console.log("Suraj posted data", res);
          toast.success("Date Saved Successfully", {
            duration: 3000,
          });
        })
        .catch((error) => {
          toast.error("Error in API", {
            duration: 3000,
          });
          console.log("error in posting data to user tracking", error);
        });
    } else {
      toast.error("Please enter a value", {
        duration: 3000,
      });
    }
  };

  setTableData = () => {
    let {
      isLoading,
      domain_messageid_files,
      discountDataObject,
      program,
      source,
      uploadDateFlag,
      sourceSelected,
      scrollLeft,
      scrollRight,
      adminHistoryComment,
      engagedCommentHistory,
      campaignedCommentsHistory,
      popupData,
    } = JSON.parse(JSON.stringify(this.state));
    let {
      rowDataList,
      tabName,
      dealMap,
      updateDealMap,
      confirmDealMap,
      updateConfirmDealMap,
      handleDealConfirm,
      wonListCommentsHistory,
      lostListCommentsHistory,
      selectedPeo,
    } = this.props;

    const putSource = (Source) => {
      if (Source == "hotschedules") {
        return "HotSchedules";
      } else if (Source == "affinity") {
        return "Affinity";
      } else {
        return Source;
      }
    };

    const updateHistoryComments = (id, comment, rowData) => {
      let {
        adminHistoryComment,
        engagedCommentHistory,
        campaignedCommentsHistory,
      } = JSON.parse(JSON.stringify(this.state));
      if (rowData.tab === "tab3") {
        adminHistoryComment[id] = comment;
        this.setState({ adminHistoryComment });
      } else if (rowData.tab === "tab1") {
        engagedCommentHistory[id] = comment;
        this.setState({ engagedCommentHistory });
      } else if (rowData.tab === "tab2") {
        campaignedCommentsHistory[id] = comment;
        this.setState({ campaignedCommentsHistory });
      }
    };

    let columns = {
      tab1: [
        {
          title: "Uploaded Date",
          field: "campaignDate",
          filterPlaceholder: "Filter",

          render: (rowData) => {
            if (rowData?.campaignDate) {
              return (
                <input
                  type="text"
                  placeholder="M/D/YY"
                  disabled={!rowData?.isPreUnderwritten}
                  className="campaignDateBox"
                  onBlur={(e) => {
                    this.handleCampaignDateButtonClick(rowData.domain, e);
                  }}
                  value={rowData?.campaignDate}
                ></input>
              );
            } else {
              return (
                <input
                  type="text"
                  placeholder="M/D/YY"
                  disabled={!rowData?.isPreUnderwritten}
                  className="campaignDateBox"
                  onBlur={(e) => {
                    this.handleCampaignDateButtonClick(rowData.domain, e);
                  }}
                ></input>
              );
            }
          },
          cellStyle: {
            // padding: "2px 0px 2px 2px",
            minWidth: "6rem",
          },
          headerStyle: {
            padding: "2px 0px 2px 2px",
            minWidth: "6rem",
          },
          filterCellStyle: {
            // padding: "2px 0px 2px 2px",
          },
        },
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "10rem",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          render: (rowData) => {
            let random_user = Object.keys(rowData?.email_specific || {})[0];

            return (
              <Clamp lines={2}>
                <span
                  onClick={() => {
                    sessionStorage.setItem("hideButtons", "true");
                    sessionStorage.setItem("showAllFields", true);
                    sessionStorage.setItem("salespersonMailCampaign", true);
                  }}
                >
                  {rowData?.ispeowith &&
                  rowData?.ispeowith.toLowerCase() !== "no" ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Link
                        to={`/Quote/?user=${random_user}&viewButton=true`}
                        style={{ color: "black" }}
                      >
                        {this.camelCase(rowData?.companyName || "-")}
                      </Link>
                      <Tooltip
                        title={
                          rowData?.ispeowith
                            ? rowData?.ispeowith
                            : "The Company is with PEO Relationship"
                        }
                        arrow
                      >
                        <p>
                          <FaRegHandshake size={15} color="#427cbc" />
                        </p>
                      </Tooltip>
                    </span>
                  ) : (
                    <Link
                      to={`/Quote/?user=${random_user}&viewButton=true`}
                      style={{ color: "black" }}
                    >
                      {this.camelCase(rowData?.companyName || "-")}
                    </Link>
                  )}
                </span>
              </Clamp>
            );
          },
          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "15rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 10px",
            minWidth: "18rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },
        },
        {
          title: "Payroll",
          field: "payroll",
          filterPlaceholder: "Filter",
          render: (rowData) => numeral(rowData?.payroll).format("$0,0"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "7rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData.city),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 6px",
            paddingRight: "20px",
            minWidth: "5rem",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Emod",
          field: "Emod",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateEmod(dataToSave);
              }
            };

            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.Emod}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "SFF",
          field: "SFF",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateSFF(dataToSave);
              }
            };
            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.sff}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },

          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            // if (rowData.step === "Submitted") {
            //   style.background = "#5ADA38";
            // } else if (rowData.step === "User Submitted") {
            //   style.background = "#5ADA38";
            // } else if (rowData.step === "Proposal Generated") {
            //   style.backgroundColor = "#5ADA38";
            // } else if (rowData.step === "UW Portal") {
            //   style.backgroundColor = "#ffd737ac";
            // } else if (rowData.step === "Approved") {
            //   style.backgroundColor = "#87FA4D";
            // } else if (rowData.step === "Rejected") {
            //   style.backgroundColor = "#fa6d3e";
            // } else if (rowData.step === "Initial Pricing") {
            //   style.backgroundColor = "#87FA20";
            // } else if (rowData.step === "Location's and Payroll") {
            //   style.backgroundColor = "#FFD737";
            // } else if (Number(rowData.visitCount) > 0) {
            //   style.backgroundColor = "#FFD737";
            // } else {
            //   style.backgroundColor = "#FFF";
            // }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "190px",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
            minWidth: "190px",
          },
          render: (rowData) => (
            <div style={this.styles(rowData?.step, rowData)}>
              {rowData?.step || "-"}
            </div>
          ),
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              if (dataToSave) this.updateAdminComment(dataToSave, rowData);
            };
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.salesID || rowData?.domain}_comment`}
                  value={
                    typeof engagedCommentHistory?.[rowData?.salesID]?.[0] ===
                      "object" ||
                    typeof engagedCommentHistory?.[rowData?.domain]?.[0] ===
                      "object"
                      ? engagedCommentHistory?.[rowData?.salesID]?.[0]
                          ?.comment ||
                        engagedCommentHistory?.[rowData?.domain]?.[0]?.comment
                      : engagedCommentHistory?.[rowData?.salesID]?.[0] ||
                        engagedCommentHistory?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={updateHistoryComments}
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.domain ? "domain" : "id"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={
                    engagedCommentHistory?.[rowData?.salesID] ||
                    engagedCommentHistory?.[rowData?.domain]
                  }
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          engagedCommentHistory?.[rowData?.salesID] ||
                            engagedCommentHistory?.[rowData?.domain],
                          rowData.createdDate
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID])
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 15px 6px 23px",
            minWidth: "140px",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "140px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: "PreUW?",
          field: "isPreUnderwritten",
          filterPlaceholder: "Filter",
          lookup: { true: "Yes", false: "No" },
          cellStyle: {
            padding: "6px 0 6px 20px",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0px 6px 13px",
            minWidth: "100px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
          render: (rowData) => (rowData?.isPreUnderwritten ? "YES" : "NO"),
        },
        {
          title: (
            <img
              src={require("../../images/download_black.png")}
              alt=""
              style={{
                width: "20px",
                height: "20px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          sorting: false,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (
              domain_messageid_files[rowData.domain]?.fileListToStore?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .fileListToStore) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (domain_messageid_files[rowData.domain]?.preUwFiles?.length) {
              for (let file of domain_messageid_files[rowData.domain]
                .preUwFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            let totalFiles =
              (domain_messageid_files[rowData.domain]?.dashboardFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.preUwFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.fileListToStore
                ?.length || 0);

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files[rowData.domain]
                              .dashboardFiles || []
                          }
                          preUwFiles={
                            domain_messageid_files[rowData.domain].preUwFiles ||
                            []
                          }
                          fileListToStore={
                            domain_messageid_files[rowData.domain]
                              .fileListToStore || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/download_black.png")}
                  alt=""
                  style={
                    totalFiles > 0
                      ? {
                          width: "20px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "20px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${this.camelCase(
                  rowData?.salespersonName.slice(0, 15)
                )}...`;
              } else {
                name = rowData.salespersonName;
              }
            } catch (error) {}
            if (rowData?.special_catergory) {
              return (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{name} </div>
                  <CiLinkedin size={20} color="#427cbc" />
                </span>
              );
            } else {
              return name;
            }
          },
        },
        // {
        //   title: "Source",
        //   field: "source",
        //   filterPlaceholder: "Filter",
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "170px",
        //   },
        //   headerStyle: {
        //     paddingLeft: "10px",
        //   },
        //   filterCellStyle: {
        //     padding: "2px 18px 2px 5px",
        //   },
        //   render: (rowData) => (
        //     <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>
        //   ),
        // },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.comment?.[0] === "object"
                      ? rowData?.comment?.[0]?.comment
                      : rowData?.comment?.[0] || rowData?.comment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(rowData?.comment),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "comment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        // {
        //   title: "SM Comments",
        //   field: "smcomment_download",
        //   filterPlaceholder: "Filter",
        //   render: (rowData) => {
        //     return (
        //       <div className="d-flex">
        //         <textarea
        //           defaultValue={
        //             typeof rowData?.smcomment?.[0] === "object"
        //               ? rowData?.smcomment?.[0]?.comment
        //               : rowData?.smcomment?.[0] || rowData?.smcomment || ""
        //           }
        //           disabled
        //           rows="1"
        //           style={{
        //             width: "100%",
        //             borderRadius: "3px",
        //             backgroundColor: this.setCommentBoxColor(
        //               rowData?.smcomment
        //             ),
        //           }}
        //         ></textarea>
        //         <Tooltip title="Click to view comment history" arrow>
        //           <button
        //             className="comment-btn"
        //             onClick={
        //               () =>
        //                 this.handleCommentHistory(
        //                   [rowData],
        //                   `${rowData?.salesID}`,
        //                   "smcomment"
        //                 )
        //               // console.log([rowData], `${rowData.salesID}`)
        //             }
        //           >
        //             ...
        //           </button>
        //         </Tooltip>
        //       </div>
        //     );
        //   },
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "230px",
        //   },
        //   headerStyle: {
        //     minWidth: "230px",
        //     paddingLeft: "10px",
        //   },
        //   filterCellStyle: {
        //     padding: "2px 15px 2px 5px",
        //   },
        // },
        {
          title: "Sold / Not Sold",
          field: "dealFlagFilter",
          filtering: true,
          sorting: false,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;
            return (
              <DealClosedLostButtons
                recordDetails={rowData.recordDetails}
                isClosed={isClosed}
                isLost={isLost}
                updateDealMap={updateDealMap}
                disabled={confirm}
                updatePopUpData={this.updatePopUpData}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "160px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
            color: "black",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "To SalesPerson",
          field: "domain",
          sorting: false,
          cellStyle: {
            padding: "6px 0 6px 20px",
            minWidth: "160px",
          },
          headerStyle: {
            padding: "6px 0px 6px 13px",
            minWidth: "160px",
            color: "black",
          },
          render: (rowData) => {
            return (
              <button
                onClick={() => {
                  this.handleButtonClick(rowData.domain);
                }}
                style={{
                  padding: "5px 10px",
                  // backgroundColor: "#007bff",
                  // color: "white",
                  border: "none",
                  borderRadius: "4px",
                }}
                disabled={!rowData?.isPreUnderwritten}
              >
                Send to SalesPerson
              </button>
            );
          },
        },
        // {
        //   title: "Confirm Deal",
        //   field: "dealConfirmed",
        //   filtering: false,
        //   render: (rowData) => {
        //     let key = rowData.domain || rowData.salesID;
        //     let isClosed = false,
        //       isLost = false;
        //     if (key in dealMap) {
        //       isClosed = dealMap[key] === "true" ? true : false;
        //       isLost = dealMap[key] === "false" ? true : false;
        //     } else {
        //       isClosed = rowData.dealFlag === "closed" ? true : false;
        //       isLost = rowData.dealFlag === "lost" ? true : false;
        //     }
        //     let confirm = false;
        //     if (confirmDealMap?.[key] === "true") confirm = true;
        //     else if (rowData.dealConfirmed === "true") confirm = true;

        //     return (
        //       <ConfirmDealButton
        //         updatePopUpData={this.updatePopUpData}
        //         rowData={rowData}
        //         isClosed={isClosed}
        //         isLost={isLost}
        //         updateConfirmDealMap={updateConfirmDealMap}
        //         disabled={confirm}
        //         handleDealConfirm={handleDealConfirm}
        //         tabName={tabName}
        //       />
        //     );
        //   },
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "150px",
        //   },
        //   headerStyle: {
        //     padding: "6px 5px 6px 20px",
        //   },
        // },
      ],
      tab2: [
        {
          title: "Uploaded Date",
          field: "campaignDate",
          filterPlaceholder: "Filter",

          render: (rowData) => {
            if (rowData?.campaignDate) {
              return (
                <input
                  type="text"
                  placeholder="M/D/YY"
                  disabled={!rowData?.isPreUnderwritten}
                  className="campaignDateBox"
                  onBlur={(e) => {
                    this.handleCampaignDateButtonClick(rowData.domain, e);
                  }}
                  value={rowData?.campaignDate}
                ></input>
              );
            } else {
              return (
                <input
                  type="text"
                  placeholder="M/D/YY"
                  disabled={!rowData?.isPreUnderwritten}
                  className="campaignDateBox"
                  onBlur={(e) => {
                    this.handleCampaignDateButtonClick(rowData.domain, e);
                  }}
                ></input>
              );
            }
          },
          cellStyle: {
            // padding: "2px 0px 2px 2px",
            minWidth: "6rem",
          },
          headerStyle: {
            padding: "2px 0px 2px 2px",
            minWidth: "6rem",
          },
          filterCellStyle: {
            // padding: "2px 0px 2px 2px",
          },
        },
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 3px",
            minWidth: "10rem",
            textAlign: "right",
          },
          headerStyle: {
            padding: "3px 0px 3px 6px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "18rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 10px",
            minWidth: "12rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },

          render: (rowData) => {
            let random_user = Object.keys(rowData?.email_specific || {})[0];

            return (
              <Clamp lines={2}>
                <span
                  onClick={() => {
                    sessionStorage.setItem("hideButtons", "true");
                    sessionStorage.setItem("showAllFields", true);
                    sessionStorage.setItem("salespersonMailCampaign", true);
                  }}
                >
                  {rowData?.ispeowith &&
                  rowData?.ispeowith.toLowerCase() !== "no" ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Link
                        to={`/Quote/?user=${random_user}&viewButton=true`}
                        style={{ color: "black" }}
                      >
                        {this.camelCase(rowData?.companyName || "-")}
                      </Link>
                      <Tooltip
                        title={
                          rowData?.ispeowith
                            ? rowData?.ispeowith
                            : "The Company is with PEO Relationship"
                        }
                        arrow
                      >
                        <p>
                          <FaRegHandshake size={15} color="#427cbc" />
                        </p>
                      </Tooltip>
                    </span>
                  ) : (
                    <Link
                      to={`/Quote/?user=${random_user}&viewButton=true`}
                      style={{ color: "black" }}
                    >
                      {this.camelCase(rowData?.companyName || "-")}
                    </Link>
                  )}
                </span>
              </Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) => numeral(rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "7rem",
            wordBreak: "break-all",
            textAlign: "right",
          },

          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          filterPlaceholder: "Filter",
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData.city),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 6px",
            paddingRight: "20px",
            minWidth: "5rem",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Emod",
          field: "Emod",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateEmod(dataToSave);
              }
            };

            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.Emod}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "SFF",
          field: "SFF",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateSFF(dataToSave);
              }
            };
            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.sff}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },

          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            // if (rowData.step === "Submitted") {
            //   style.background = "#5ADA38";
            // } else if (rowData.step === "Proposal Generated") {
            //   style.backgroundColor = "#5ADA38";
            // } else if (rowData.step === "UW Portal") {
            //   style.backgroundColor = "#ffd737ac";
            // } else if (rowData.step === "Approved") {
            //   style.backgroundColor = "#87FA4D";
            // } else if (rowData.step === "Rejected") {
            //   style.backgroundColor = "#fa6d3e";
            // } else if (rowData.step === "Initial Pricing") {
            //   style.backgroundColor = "#87FA20";
            // } else if (rowData.step === "Location's and Payroll") {
            //   style.backgroundColor = "#FFD737";
            // } else if (Number(rowData.visitCount) > 0) {
            //   style.backgroundColor = "#FFD737";
            // } else {
            //   style.backgroundColor = "#FFF";
            // }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "190px",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
            minWidth: "190px",
          },
          render: (rowData) => (
            <div style={this.styles(rowData?.step, rowData)}>
              {rowData?.step || "-"}
            </div>
          ),
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              if (dataToSave) this.updateAdminComment(dataToSave, rowData);
            };
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.domain}_comment`}
                  value={
                    typeof campaignedCommentsHistory?.[rowData?.domain]?.[0] ===
                    "object"
                      ? campaignedCommentsHistory?.[rowData?.domain]?.[0]
                          ?.comment
                      : campaignedCommentsHistory?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={updateHistoryComments}
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.domain ? "domain" : "id"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={campaignedCommentsHistory?.[rowData?.domain]}
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          campaignedCommentsHistory?.[rowData?.domain],
                          rowData?.visitDate
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID])
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 23px",
            minWidth: "140px",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "140px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: "Pre UW?",
          field: "isPreUnderwritten",
          filterPlaceholder: "Filter",
          lookup: { true: "Yes", false: "No" },
          cellStyle: {
            padding: "6px 0 6px 20px",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0px 6px 13px",
            minWidth: "100px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
          render: (rowData) => (rowData.isPreUnderwritten ? "YES" : "NO"),
        },
        {
          title: (
            <img
              src={require("../../images/download_black.png")}
              alt=""
              style={{
                width: "20px",
                height: "20px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          sorting: false,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (
              domain_messageid_files[rowData.domain]?.fileListToStore?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .fileListToStore) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (domain_messageid_files[rowData.domain]?.preUwFiles?.length) {
              for (let file of domain_messageid_files[rowData.domain]
                .preUwFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            let totalFiles =
              (domain_messageid_files[rowData.domain]?.dashboardFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.preUwFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.fileListToStore
                ?.length || 0);

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files[rowData.domain]
                              .dashboardFiles || []
                          }
                          preUwFiles={
                            domain_messageid_files[rowData.domain].preUwFiles ||
                            []
                          }
                          fileListToStore={
                            domain_messageid_files[rowData.domain]
                              .fileListToStore || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/download_black.png")}
                  alt=""
                  style={
                    totalFiles > 0
                      ? {
                          width: "20px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "20px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData.salespersonName;
              }
            } catch (error) {}
            if (rowData?.special_catergory) {
              return (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{name} </div>
                  <CiLinkedin size={20} color="#427cbc" />
                </span>
              );
            } else {
              return name;
            }
          },
        },
        // {
        //   title: "Source",
        //   field: "source",
        //   filterPlaceholder: "Filter",
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "170px",
        //   },
        //   headerStyle: {
        //     paddingLeft: "10px",
        //   },
        //   filterCellStyle: {
        //     padding: "2px 18px 2px 5px",
        //   },
        //   render: (rowData) => (
        //     <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>
        //   ),
        // },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.comment?.[0] === "object"
                      ? rowData?.comment?.[0]?.comment
                      : rowData?.comment?.[0] || rowData?.comment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(rowData?.comment),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "comment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        // {
        //   title: "SM Comments",
        //   field: "smcomment_download",
        //   filterPlaceholder: "Filter",
        //   render: (rowData) => {
        //     return (
        //       <div className="d-flex">
        //         <textarea
        //           defaultValue={
        //             typeof rowData?.smcomment?.[0] === "object"
        //               ? rowData?.smcomment?.[0]?.comment
        //               : rowData?.smcomment?.[0] || rowData?.smcomment || ""
        //           }
        //           disabled
        //           rows="1"
        //           style={{
        //             width: "100%",
        //             borderRadius: "3px",
        //             backgroundColor: this.setCommentBoxColor(
        //               rowData?.smcomment
        //             ),
        //           }}
        //         ></textarea>
        //         <Tooltip title="Click to view comment history" arrow>
        //           <button
        //             className="comment-btn"
        //             onClick={
        //               () =>
        //                 this.handleCommentHistory(
        //                   [rowData],
        //                   `${rowData?.salesID}`,
        //                   "smcomment"
        //                 )
        //               // console.log([rowData], `${rowData.salesID}`)
        //             }
        //           >
        //             ...
        //           </button>
        //         </Tooltip>
        //       </div>
        //     );
        //   },
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "230px",
        //   },
        //   headerStyle: {
        //     minWidth: "230px",
        //     paddingLeft: "10px",
        //   },
        //   filterCellStyle: {
        //     padding: "2px 15px 2px 5px",
        //   },
        // },
        {
          title: "Sold / Not Sold",
          field: "dealFlagFilter",
          filtering: true,
          sorting: false,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;
            return (
              <DealClosedLostButtons
                recordDetails={rowData.recordDetails}
                isClosed={isClosed}
                isLost={isLost}
                updateDealMap={updateDealMap}
                disabled={confirm}
                updatePopUpData={this.updatePopUpData}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "160px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
            color: "black",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "To SalesPerson",
          field: "domain",
          sorting: false,

          cellStyle: {
            padding: "6px 0 6px 20px",
            minWidth: "160px",
          },
          headerStyle: {
            padding: "6px 0px 6px 13px",
            minWidth: "160px",
            color: "black",
          },
          render: (rowData) => {
            return (
              <button
                onClick={() => {
                  this.handleButtonClick(rowData.domain);
                }}
                style={{
                  padding: "5px 10px",
                  // backgroundColor: "#007bff",
                  // color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                disabled={!rowData?.isPreUnderwritten}
              >
                Send to SalesPerson
              </button>
            );
          },
        },
        // {
        //   title: "Confirm Deal",
        //   field: "dealConfirmed",
        //   filtering: false,
        //   render: (rowData) => {
        //     let key = rowData.domain || rowData.salesID;
        //     let isClosed = false,
        //       isLost = false;
        //     if (key in dealMap) {
        //       isClosed = dealMap[key] === "true" ? true : false;
        //       isLost = dealMap[key] === "false" ? true : false;
        //     } else {
        //       isClosed = rowData.dealFlag === "closed" ? true : false;
        //       isLost = rowData.dealFlag === "lost" ? true : false;
        //     }
        //     let confirm = false;
        //     if (confirmDealMap?.[key] === "true") confirm = true;
        //     else if (rowData.dealConfirmed === "true") confirm = true;

        //     return (
        //       <ConfirmDealButton
        //         updatePopUpData={this.updatePopUpData}
        //         rowData={rowData}
        //         isClosed={isClosed}
        //         isLost={isLost}
        //         updateConfirmDealMap={updateConfirmDealMap}
        //         disabled={confirm}
        //         handleDealConfirm={handleDealConfirm}
        //         tabName={tabName}
        //       />
        //     );
        //   },
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "150px",
        //   },
        //   headerStyle: {
        //     padding: "6px 5px 6px 20px",
        //   },
        // },
      ],
      tab3: [
        {
          title: "Uploaded Date",
          field: "createdDate",
          filterPlaceholder: "Filter",
          customSort: (a, b) => this.dateSort(a, b, "createdDate"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            padding: "2px 0px 2px 2px",
            minWidth: "7rem",
          },
          headerStyle: {
            padding: "2px 0px 2px 2px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 2px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData?.createdDate || "-"}</Clamp>
          ),
        },
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData?.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "10rem",
            textAlign: "right",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "18rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 10px",
            minWidth: "12rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              <span>
                {rowData?.ispeowith &&
                rowData?.ispeowith.toLowerCase() !== "no" ? (
                  <span
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => this.onClickCompanyName(rowData)}
                  >
                    <Link to={"/Quote"} style={{ color: "black" }}>
                      {this.camelCase(rowData?.companyName)}
                    </Link>
                    <Tooltip
                      title={
                        rowData?.ispeowith
                          ? rowData?.ispeowith
                          : "The Company is with PEO Relationship"
                      }
                      arrow
                    >
                      <p>
                        <FaRegHandshake size={15} color="#427cbc" />
                      </p>
                    </Tooltip>
                  </span>
                ) : (
                  <span onClick={() => this.onClickCompanyName(rowData)}>
                    <Link to={"/Quote"} style={{ color: "black" }}>
                      {this.camelCase(rowData?.companyName)}
                    </Link>
                  </span>
                )}
              </span>
            </Clamp>
          ),
        },
        {
          title: "Payroll",
          field: "total_payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) =>
            numeral(rowData?.total_payroll).format("$0,0") || "$0",
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "7rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",

          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "6rem",
          },
          render: (rowData) => this.camelCase(rowData?.state || ""),
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "6rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData?.city) || "",
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 6px",
            paddingRight: "20px",
            minWidth: "5rem",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Emod",
          field: "Emod",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateEmod(dataToSave);
              }
            };

            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.Emod}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "SFF",
          field: "SFF",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateSFF(dataToSave);
              }
            };
            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.sff}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },

          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            // if (rowData.step === "Submitted") {
            //   style.background = "#5ADA38";
            // } else if (rowData.step === "Proposal Generated") {
            //   style.backgroundColor = "#5ADA38";
            // } else if (rowData.step === "UW Portal") {
            //   style.backgroundColor = "#ffd737ac";
            // } else if (rowData.step === "Approved") {
            //   style.backgroundColor = "#87FA4D";
            // } else if (rowData.step === "Rejected") {
            //   style.backgroundColor = "#fa6d3e";
            // } else if (rowData.step === "Initial Pricing") {
            //   style.backgroundColor = "#87FA20";
            // } else if (rowData.step === "Location's and Payroll") {
            //   style.backgroundColor = "#FFD737";
            // } else if (Number(rowData.visitCount) > 0) {
            //   style.backgroundColor = "#FFD737";
            // } else {
            //   style.backgroundColor = "#FFF";
            // }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "190px",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
            minWidth: "190px",
          },
          render: (rowData) => (
            <div style={this.styles(rowData?.step, rowData)}>
              {rowData?.step || "-"}
            </div>
          ),
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              this.updateAdminComment(dataToSave, rowData);
            };
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.salesID || rowData?.domain}_comment`}
                  value={
                    typeof adminHistoryComment?.[rowData?.salesID]?.[0] ===
                      "object" ||
                    typeof adminHistoryComment?.[rowData?.domain]?.[0] ===
                      "object"
                      ? adminHistoryComment?.[rowData?.salesID]?.[0]?.comment ||
                        adminHistoryComment?.[rowData?.domain]?.[0]?.comment
                      : adminHistoryComment?.[rowData?.salesID]?.[0] ||
                        adminHistoryComment?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={updateHistoryComments}
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.salesID ? "id" : "domain"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={
                    adminHistoryComment?.[rowData?.salesID] ||
                    adminHistoryComment?.[rowData?.domain]
                  }
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          adminHistoryComment?.[rowData?.salesID],
                          rowData?.createdDate,
                          rowData?.salesID
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID])
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 15px 6px 23px",
            minWidth: "140px",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "140px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: (
            <img
              src={require("../../images/download_black.png")}
              alt=""
              style={{
                width: "20px",
                height: "20px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          sorting: false,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files?.[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files?.[rowData.domain]
                ?.dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files?.[rowData.domain]
                              ?.dashboardFiles || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/download_black.png")}
                  alt=""
                  style={
                    domain_messageid_files?.[rowData.domain]?.dashboardFiles
                      ?.length
                      ? {
                          width: "20px",
                          height: "20px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "20px",
                          height: "20px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData.salespersonName;
              }
            } catch (error) {}
            if (rowData?.special_catergory) {
              return (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{name} </div>
                  <CiLinkedin size={20} color="#427cbc" />
                </span>
              );
            } else {
              return name;
            }
          },
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        // {
        //   title: "SM Comments",
        //   field: "smcomment_download",
        //   filterPlaceholder: "Filter",
        //   render: (rowData) => {
        //     return (
        //       <div className="d-flex">
        //         <textarea
        //           defaultValue={
        //             typeof rowData?.smcomment?.[0] === "object"
        //               ? rowData?.smcomment?.[0]?.comment
        //               : rowData?.smcomment?.[0] || rowData?.smcomment || ""
        //           }
        //           disabled
        //           rows="1"
        //           style={{
        //             width: "100%",
        //             borderRadius: "3px",
        //             backgroundColor: this.setCommentBoxColor(
        //               rowData?.smcomment
        //             ),
        //           }}
        //         ></textarea>
        //         <Tooltip title="Click to view comment history" arrow>
        //           <button
        //             className="comment-btn"
        //             onClick={
        //               () =>
        //                 this.handleCommentHistory(
        //                   [rowData],
        //                   `${rowData?.salesID}`,
        //                   "smcomment"
        //                 )
        //               // console.log([rowData], `${rowData.salesID}`)
        //             }
        //           >
        //             ...
        //           </button>
        //         </Tooltip>
        //       </div>
        //     );
        //   },
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "230px",
        //   },
        //   headerStyle: {
        //     minWidth: "230px",
        //     paddingLeft: "10px",
        //   },
        //   filterCellStyle: {
        //     padding: "2px 15px 2px 5px",
        //   },
        // },
        {
          title: "Sold / Not Sold",
          field: "dealFlagFilter",
          filtering: true,
          sorting: false,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;

            return (
              <DealClosedLostButtons
                recordDetails={rowData.recordDetails}
                isClosed={isClosed}
                isLost={isLost}
                updateDealMap={updateDealMap}
                disabled={confirm}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "160px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
            color: "black",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        // {
        //   title: "Confirm Deal",
        //   field: "dealConfirmed",
        //   filtering: false,
        //   render: (rowData) => {
        //     let key = rowData.domain || rowData.salesID;
        //     let isClosed = false,
        //       isLost = false;
        //     if (key in dealMap) {
        //       isClosed = dealMap[key] === "true" ? true : false;
        //       isLost = dealMap[key] === "false" ? true : false;
        //     } else {
        //       isClosed = rowData.dealFlag === "closed" ? true : false;
        //       isLost = rowData.dealFlag === "lost" ? true : false;
        //     }
        //     let confirm = false;
        //     if (confirmDealMap?.[key] === "true") confirm = true;
        //     else if (rowData.dealConfirmed === "true") confirm = true;

        //     return (
        //       <ConfirmDealButton
        //         updatePopUpData={this.updatePopUpData}
        //         rowData={rowData}
        //         isClosed={isClosed}
        //         isLost={isLost}
        //         updateConfirmDealMap={updateConfirmDealMap}
        //         disabled={confirm}
        //         handleDealConfirm={handleDealConfirm}
        //         tabName={tabName}
        //       />
        //     );
        //   },
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "150px",
        //   },
        //   headerStyle: {
        //     padding: "6px 5px 6px 20px",
        //   },
        // },
      ],
      tab4: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "10rem",
            textAlign: "right",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "18rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 12px",
            minWidth: "12rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return (
                <Clamp lines={2}>
                  <span onClick={() => this.onClickCompanyName(rowData)}>
                    <Link to={"/Quote"} style={{ color: "black" }}>
                      {this.camelCase(rowData.companyName)}
                    </Link>
                  </span>
                </Clamp>
              );
            } else if (rowData.tab === "tab1") {
              let random_user = Object.keys(rowData?.email_specific || {})[0];
              return (
                <Clamp lines={2}>
                  <span
                    onClick={() => {
                      sessionStorage.setItem("hideButtons", "true");
                      sessionStorage.setItem("showAllFields", true);
                      sessionStorage.setItem("salespersonMailCampaign", true);
                    }}
                  >
                    <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                      {this.camelCase(rowData?.companyName || "-")}
                    </Link>
                  </span>
                </Clamp>
              );
            }
            return (
              <Clamp lines={2}>
                <span>{this.camelCase(rowData.companyName)}</span>
              </Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "total_payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) =>
            numeral(rowData.total_payroll || rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",

          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData.city),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 6px",
            paddingRight: "20px",
            minWidth: "5rem",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Emod",
          field: "Emod",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateEmod(dataToSave);
              }
            };

            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.Emod}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "SFF",
          field: "SFF",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateSFF(dataToSave);
              }
            };
            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.sff}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },

          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            // if (rowData.step === "Submitted") {
            //   style.background = "#5ADA38";
            // } else if (rowData.step === "Proposal Generated") {
            //   style.backgroundColor = "#5ADA38";
            // } else if (rowData.step === "UW Portal") {
            //   style.backgroundColor = "#ffd737ac";
            // } else if (rowData.step === "Approved") {
            //   style.backgroundColor = "#87FA4D";
            // } else if (rowData.step === "Rejected") {
            //   style.backgroundColor = "#fa6d3e";
            // } else if (rowData.step === "Initial Pricing") {
            //   style.backgroundColor = "#87FA20";
            // } else if (rowData.step === "Location's and Payroll") {
            //   style.backgroundColor = "#FFD737";
            // } else if (Number(rowData.visitCount) > 0) {
            //   style.backgroundColor = "#FFD737";
            // } else {
            //   style.backgroundColor = "#FFF";
            // }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
          },
          render: (rowData) => (
            <div style={this.styles(rowData?.step, rowData)}>
              {rowData?.step || "-"}
            </div>
          ),
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              if (dataToSave)
                this.updateAdminWonLossComment(dataToSave, rowData);
            };
            // console.log(rowData.salesID, rowData.domain)
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.salesID || rowData?.domain}_comment`}
                  value={
                    typeof wonListCommentsHistory?.[rowData?.salesID]?.[0] ===
                      "object" ||
                    typeof wonListCommentsHistory?.[rowData?.domain]?.[0] ===
                      "object"
                      ? wonListCommentsHistory?.[rowData?.salesID]?.[0]
                          ?.comment ||
                        wonListCommentsHistory?.[rowData?.domain]?.[0]?.comment
                      : wonListCommentsHistory?.[rowData?.salesID]?.[0] ||
                        wonListCommentsHistory?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={
                    this.props.updatewonAdminHistoryComments
                  }
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.domain ? "domain" : "id"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={
                    wonListCommentsHistory?.[rowData?.salesID] ||
                    wonListCommentsHistory?.[rowData?.domain]
                  }
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          wonListCommentsHistory?.[rowData?.salesID] ||
                            wonListCommentsHistory?.[rowData?.domain],
                          rowData?.createdDate
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID])
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 15px 6px 23px",
            minWidth: "140px",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "140px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: (
            <img
              src={require("../../images/download_black.png")}
              alt=""
              style={{
                width: "20px",
                height: "20px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          sorting: false,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files?.[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files?.[rowData.domain]
                ?.dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files?.[rowData.domain]
                              ?.dashboardFiles || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/download_black.png")}
                  alt=""
                  style={
                    domain_messageid_files?.[rowData.domain]?.dashboardFiles
                      ?.length
                      ? {
                          width: "20px",
                          height: "20px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "20px",
                          height: "20px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData.salespersonName;
              }
            } catch (error) {}
            if (rowData?.special_catergory) {
              return (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{name} </div>
                  <CiLinkedin size={20} color="#427cbc" />
                </span>
              );
            } else {
              return name;
            }
          },
        },
        // {
        //   title: "Source",
        //   field: "source",
        //   filterPlaceholder: "Filter",
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "170px",
        //   },
        //   headerStyle: {
        //     paddingLeft: "10px",
        //   },
        //   filterCellStyle: {
        //     padding: "2px 18px 2px 5px",
        //   },
        //   render: (rowData) => {
        //     if (rowData.tab === "tab3") {
        //       return <Clamp lines={1}>Salesforce</Clamp>;
        //     }
        //     return <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>;
        //   },
        // },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.comment?.[0] === "object"
                      ? rowData?.comment?.[0]?.comment
                      : rowData?.comment?.[0] || rowData?.comment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(rowData?.comment),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "comment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        // {
        //   title: "SM Comments",
        //   field: "smcomment_download",
        //   filterPlaceholder: "Filter",
        //   render: (rowData) => {
        //     return (
        //       <div className="d-flex">
        //         <textarea
        //           defaultValue={
        //             typeof rowData?.smcomment?.[0] === "object"
        //               ? rowData?.smcomment?.[0]?.comment
        //               : rowData?.smcomment?.[0] || rowData?.smcomment || ""
        //           }
        //           disabled
        //           rows="1"
        //           style={{
        //             width: "100%",
        //             borderRadius: "3px",
        //             backgroundColor: this.setCommentBoxColor(
        //               rowData?.smcomment
        //             ),
        //           }}
        //         ></textarea>
        //         <Tooltip title="Click to view comment history" arrow>
        //           <button
        //             className="comment-btn"
        //             onClick={
        //               () =>
        //                 this.handleCommentHistory(
        //                   [rowData],
        //                   `${rowData?.salesID}`,
        //                   "smcomment"
        //                 )
        //               // console.log([rowData], `${rowData.salesID}`)
        //             }
        //           >
        //             ...
        //           </button>
        //         </Tooltip>
        //       </div>
        //     );
        //   },
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "230px",
        //   },
        //   headerStyle: {
        //     minWidth: "230px",
        //     paddingLeft: "10px",
        //   },
        //   filterCellStyle: {
        //     padding: "2px 15px 2px 5px",
        //   },
        // },
      ],
      tab5: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "10rem",
            textAlign: "right",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "18rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 12px",
            minWidth: "12rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return (
                <Clamp lines={2}>
                  <span onClick={() => this.onClickCompanyName(rowData)}>
                    <Link to={"/Quote"} style={{ color: "black" }}>
                      {this.camelCase(rowData.companyName)}
                    </Link>
                  </span>
                </Clamp>
              );
            } else if (rowData.tab === "tab1") {
              let random_user = Object.keys(rowData?.email_specific || {})[0];
              return (
                <Clamp lines={2}>
                  <span
                    onClick={() => {
                      sessionStorage.setItem("hideButtons", "true");
                      sessionStorage.setItem("showAllFields", true);
                      sessionStorage.setItem("salespersonMailCampaign", true);
                    }}
                  >
                    <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                      {this.camelCase(rowData?.companyName || "-")}
                    </Link>
                  </span>
                </Clamp>
              );
            }
            return (
              <Clamp lines={2}>
                <span>{this.camelCase(rowData.companyName)}</span>
              </Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "total_payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) =>
            numeral(rowData.total_payroll || rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",

          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData.city),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 6px",
            paddingRight: "20px",
            minWidth: "5rem",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Emod",
          field: "Emod",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateEmod(dataToSave);
              }
            };

            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.Emod}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 20px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "SFF",
          field: "SFF",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => {
            const callbackFunction = (dataToSave) => {
              if (dataToSave) {
                this.updateSFF(dataToSave);
              }
            };
            return (
              <div className="d-flex">
                <UpdateTextField
                  value={rowData.sff}
                  callback={callbackFunction}
                  rowDataID={rowData?.domain}
                />
              </div>
            );
          },

          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            // if (rowData.step === "Submitted") {
            //   style.background = "#5ADA38";
            // } else if (rowData.step === "Proposal Generated") {
            //   style.backgroundColor = "#5ADA38";
            // } else if (rowData.step === "UW Portal") {
            //   style.backgroundColor = "#ffd737ac";
            // } else if (rowData.step === "Approved") {
            //   style.backgroundColor = "#87FA4D";
            // } else if (rowData.step === "Rejected") {
            //   style.backgroundColor = "#fa6d3e";
            // } else if (rowData.step === "Initial Pricing") {
            //   style.backgroundColor = "#87FA20";
            // } else if (rowData.step === "Location's and Payroll") {
            //   style.backgroundColor = "#FFD737";
            // } else if (Number(rowData.visitCount) > 0) {
            //   style.backgroundColor = "#FFD737";
            // } else {
            //   style.backgroundColor = "#FFF";
            // }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
          },
          render: (rowData) => (
            <div style={this.styles(rowData?.step, rowData)}>
              {rowData?.step || "-"}
            </div>
          ),
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              if (dataToSave)
                this.updateAdminWonLossComment(dataToSave, rowData);
            };
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.salesID || rowData?.domain}_comment`}
                  value={
                    typeof lostListCommentsHistory?.[rowData?.salesID]?.[0] ===
                      "object" ||
                    typeof lostListCommentsHistory?.[rowData?.domain]?.[0] ===
                      "object"
                      ? lostListCommentsHistory?.[rowData?.salesID]?.[0]
                          ?.comment ||
                        lostListCommentsHistory?.[rowData?.domain]?.[0]?.comment
                      : lostListCommentsHistory?.[rowData?.salesID]?.[0] ||
                        lostListCommentsHistory?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={
                    this.props.updateLostAdminHistoryComments
                  }
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.domain ? "domain" : "id"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={
                    lostListCommentsHistory?.[rowData?.salesID] ||
                    lostListCommentsHistory?.[rowData?.domain]
                  }
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          lostListCommentsHistory?.[rowData?.salesID] ||
                            lostListCommentsHistory?.[rowData?.domain],
                          rowData?.createdDate
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID])
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 15px 6px 23px",
            minWidth: "140px",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "140px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: (
            <img
              src={require("../../images/download_black.png")}
              alt=""
              style={{
                width: "20px",
                height: "20px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          sorting: false,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files?.[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files?.[rowData.domain]
                ?.dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files?.[rowData.domain]
                              ?.dashboardFiles || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/download_black.png")}
                  alt=""
                  style={
                    domain_messageid_files?.[rowData.domain]?.dashboardFiles
                      ?.length
                      ? {
                          width: "20px",
                          height: "20px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "20px",
                          height: "20px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData.salespersonName;
              }
            } catch (error) {}
            if (rowData?.special_catergory) {
              return (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{name} </div>
                  <CiLinkedin size={20} color="#427cbc" />
                </span>
              );
            } else {
              return name;
            }
          },
        },
        // {
        //   title: "Source",
        //   field: "source",
        //   filterPlaceholder: "Filter",
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "170px",
        //   },
        //   headerStyle: {
        //     paddingLeft: "10px",
        //   },
        //   filterCellStyle: {
        //     padding: "2px 18px 2px 5px",
        //   },
        //   render: (rowData) => {
        //     if (rowData.tab === "tab3") {
        //       return <Clamp lines={1}>Salesforce</Clamp>;
        //     }
        //     return <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>;
        //   },
        // },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.comment?.[0] === "object"
                      ? rowData?.comment?.[0]?.comment
                      : rowData?.comment?.[0] || rowData?.comment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(rowData?.comment),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "comment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        // {
        //   title: "SM Comments",
        //   field: "smcomment_download",
        //   filterPlaceholder: "Filter",
        //   render: (rowData) => {
        //     return (
        //       <div className="d-flex">
        //         <textarea
        //           defaultValue={
        //             typeof rowData?.smcomment?.[0] === "object"
        //               ? rowData?.smcomment?.[0]?.comment
        //               : rowData?.smcomment?.[0] || rowData?.smcomment || ""
        //           }
        //           disabled
        //           rows="1"
        //           style={{
        //             width: "100%",
        //             borderRadius: "3px",
        //             backgroundColor: this.setCommentBoxColor(
        //               rowData?.smcomment
        //             ),
        //           }}
        //         ></textarea>
        //         <Tooltip title="Click to view comment history" arrow>
        //           <button
        //             className="comment-btn"
        //             onClick={
        //               () =>
        //                 this.handleCommentHistory(
        //                   [rowData],
        //                   `${rowData?.salesID}`,
        //                   "smcomment"
        //                 )
        //               // console.log([rowData], `${rowData.salesID}`)
        //             }
        //           >
        //             ...
        //           </button>
        //         </Tooltip>
        //       </div>
        //     );
        //   },
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "230px",
        //   },
        //   headerStyle: {
        //     minWidth: "230px",
        //     paddingLeft: "10px",
        //   },
        //   filterCellStyle: {
        //     padding: "2px 15px 2px 5px",
        //   },
        // },
      ],
    };

    if (uploadDateFlag) {
      let uploadDate = {
        title: "Upload Date",
        field: "uploadedTimeStamp",
        filterPlaceholder: "Filter",
        customSort: (a, b) => this.dateSort(a, b, "uploadedTimeStamp"),
        cellStyle: {
          wordBreak: "break-all",
          padding: "6px 0",
          minWidth: "70px",
        },
        headerStyle: {
          padding: "6px 0",
        },
        render: (rowData) => (
          <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>
        ),
      };
      columns.tab1.unshift(uploadDate);
      columns.tab2.unshift(uploadDate);
    }

    return (
      <>
        {/* {(tabName === "tab1" || tabName === "tab2" || tabName === "tab3") && (
          <LoadingOverlay active={this.state.confirmAllLoading}>
            <button
              className="btn btn-primary btnConfirmAll"
              onClick={() => {
                this.handleConfirmAll(tabName);
              }}
            >
              Confirm All
            </button>
          </LoadingOverlay>
        )} */}

        <div style={{ position: "relative" }}>
          {scrollLeft && (
            <button className="ChevronLeft" onClick={this.scrollLeft}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={this.scrollRight}>
              &gt;
            </button>
          )}

          <MaterialTable
            isLoading={
              tabName === "tab1" || tabName === "tab2" || tabName === "tab3"
                ? isLoading
                : this.props.isLoading
            }
            icons={tableIcons}
            data={rowDataList?.[selectedPeo] || []}
            columns={columns[tabName]}
            // onSelectionChange={(rows) => this.handleSelection(rows)}
            options={{
              // selection:
              //   tabName === "tab4" || tabName === "tab5" ? false : true,
              // selectionProps: (rowData) => {
              //   let key = rowData.domain || rowData.salesID;
              //   return {
              //     disabled:
              //       rowData.dealConfirmed === "true" ||
              //       (dealMap[key] !== "true" &&
              //         dealMap[key] !== "false" &&
              //         rowData.dealFlag !== "lost" &&
              //         rowData.dealFlag !== "closed"),
              //     color: "primary",
              //   };
              // },
              stickyHeader: true,
              exportButton: true,
              doubleHorizontalScroll: true,
              filtering: true,
              filter: true,
              search: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [
                10,
                25,
                50,
                75,
                100,
                tabName === "tab1"
                  ? rowDataList?.[sourceSelected].length
                    ? rowDataList?.[sourceSelected].length
                    : 0
                  : rowDataList.length
                  ? rowDataList.length
                  : 0,
              ],
              headerStyle: {
                fontSize: ismobile ? this.fontfunciton() : "1rem",
                backgroundColor: "#ffc423",
                color: "#FFF",
                padding: "3px 15px 3px 0px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? this.fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
              filterCellStyle: {
                padding: "4px 10px 4px 0px",
              },
            }}
            detailPanel={
              tabName === "tab2"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        // return this.tab2DetailPanel(rowData);
                        return (
                          <>
                            {!!rowData?.email_specific ? (
                              <Tab2DetailPanel
                                showAddContact={true}
                                rowData={rowData}
                                parentAdditionalRows={
                                  this.state.globalAddContact?.[
                                    rowData.domain
                                  ] || []
                                }
                                prim_key={rowData.domain}
                                saveContact={this.saveContact}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      },
                    },
                  ]
                : tabName === "tab1"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        // return this.tab1DetailPanel(rowData);
                        return (
                          <>
                            {!!rowData?.email_specific ? (
                              <Tab2DetailPanel
                                showAddContact={true}
                                rowData={rowData}
                                parentAdditionalRows={
                                  this.state.globalAddContact?.[
                                    rowData.domain
                                  ] || []
                                }
                                prim_key={rowData.domain}
                                saveContact={this.saveContact}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      },
                    },
                  ]
                : tabName === "tab3"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        // return this.tab3DetailPanel(rowData);
                        return (
                          <>
                            {!!rowData?.email_specific ? (
                              <Tab2DetailPanel
                                showAddContact={true}
                                rowData={rowData}
                                parentAdditionalRows={
                                  this.state.globalAddContact?.[
                                    rowData.domain
                                  ] || []
                                }
                                prim_key={rowData.domain}
                                saveContact={this.saveContact}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      },
                    },
                  ]
                : tabName === "tab4" || tabName === "tab5"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        if (rowData.tab === "tab1") {
                          return this.tab1DetailPanel(rowData);
                        } else if (rowData.tab === "tab2") {
                          return this.tab2DetailPanel(rowData);
                        } else if (rowData.tab === "tab3") {
                          return this.tab3DetailPanel(rowData);
                        }
                      },
                    },
                  ]
                : false
            }
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12 }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
          />
        </div>
      </>
    );
  };

  updateSalesDisplay = async () => {
    let _adminHistoryComment = {};
    let sessionData = await Auth.currentAuthenticatedUser();
    if (sessionData) {
      let requestBody = {};
      requestBody.salesPersonEmail = sessionData.attributes.email;
      axios
        .post(awsUrl2 + "/api/getSalesDashboardDataAdmin", requestBody)
        .then((res) => {
          let userDataList = [];
          let rowData;
          let rowDataList = [];

          userDataList = res.data.salesforce;

          for (let userDataRow in userDataList) {
            let step;
            let userData = userDataList[userDataRow];

            if (userData.formStage) {
              switch (userData.formStage) {
                case "two":
                  step = "Locations and Payroll";
                  break;
                case "three":
                  step = "Initial Pricing";
                  break;
                default:
                  break;
              }
            }

            let currProspectDetails = {
              companyProfile: userData.companyProfile,
              childrenLoc: userData.payrollData,
              emodStatesData: userData.emodStatesData,
              companyProfileAddQuestions: userData.companyProfileAddQuestions,
              uwQues: userData.uwQues,
              requestedPricing: userData.requestedPricing,
            };

            if (userData?.brokerDetails) {
              currProspectDetails.brokerDetails = userData.brokerDetails;
            }

            let total_emp = 0;
            let total_payroll = 0;

            if (userData.payrollData) {
              let childrenLoc = userData.payrollData;
              for (let locIndex in childrenLoc) {
                let loc = childrenLoc[locIndex];
                for (let ccIndex in loc.classCodesInfo) {
                  let cc = loc.classCodesInfo[ccIndex];
                  total_emp =
                    total_emp +
                    Number(cc?.ft?.value || 0) +
                    Number(cc?.pt?.value || 0);

                  total_payroll =
                    total_payroll + (numeral(cc?.payroll?.value).value() || 0);
                }
              }
            }

            rowData = {
              visitDate:
                userData.updated_timestamp &&
                moment(Number(userData.updated_timestamp))
                  .utcOffset("-0500")
                  .format("M/D/YY - hh:mm A"),
              phoneNumber:
                userData.companyProfile && userData.companyProfile.phoneNumber
                  ? userData.companyProfile.phoneNumber.value
                  : "",
              effectiveDate:
                userData.companyProfile &&
                userData.companyProfile.effectiveDate &&
                userData.companyProfile.effectiveDate.value
                  ? moment(userData.companyProfile.effectiveDate.value).format(
                      "x"
                    )
                  : "",
              contactName:
                userData.companyProfile && userData.companyProfile.firstName
                  ? userData.companyProfile.firstName.value
                  : "",
              companyName:
                userData.companyProfile && userData.companyProfile.companyName
                  ? userData.companyProfile.companyName.value
                  : "",
              step: userData.isSubmitted ? "Submitted" : step,
              currProspectDetails,
              salesID: userData.id,
              formStage: userData.formStage,
              amtrustAccountDetails: userData.amtrustAccountDetails,
              salespersonName: this.getsalespersonName(userData)
                ? this.getsalespersonName(userData)
                : "",
              total_emp,
              state: userData?.companyProfile?.state1?.value || "-",
              city: userData?.companyProfile?.cityName1?.value || "-",
              quoteData: userData.quoteData,
              address: userData.address,
              total_payroll,
              createdDate: userData.created_timestamp
                ? moment(userData.created_timestamp, "x").format("M/D/YY")
                : "-",
              companyProfile: userData.companyProfile,
              sortKeyList: userData.sortKeyList,
              salescomment: userData?.comment
                ? typeof userData?.comment === "string"
                  ? [userData?.comment]
                  : userData?.comment
                : [""],
              smcomment: userData?.smcomment
                ? typeof userData?.smcomment === "string"
                  ? [userData?.smcomment]
                  : userData?.smcomment
                : [""],
              admincomment: userData?.admincomment
                ? typeof userData?.admincomment === "string"
                  ? [userData?.admincomment]
                  : userData?.admincomment
                : [""],
              preUwFiles: userData.preUwFiles || [],
              dashboardFiles: userData.dashboardFiles || [],
              fileListToStore: userData.fileListToStore || [],
              source: userData.source,
              dealFlag:
                userData?.dealData?.status === "true"
                  ? "closed"
                  : userData?.dealData?.status === "false"
                  ? "lost"
                  : "",
              dealFlagFilter:
                userData?.dealData?.status === "true"
                  ? "won"
                  : userData?.dealData?.status === "false"
                  ? "lost"
                  : "",
              dealConfirmed: userData?.dealConfirmed,
              ispeowith: userData?.ispeowith || "",
              campaignDate: userData?.campaignDate || "",
              special_catergory: userData?.special_catergory || "",
              default_saleperson: userData?.default_saleperson || "",
              recordDetails: {
                salesId: userData.id,
              },
              tab: this.props.tabName,
            };

            createCommentForDownload(rowData, "comment");
            createCommentForDownload(rowData, "admincomment");
            createCommentForDownload(rowData, "salescomment");
            createCommentForDownload(rowData, "smcomment");

            _adminHistoryComment[rowData.salesID] = rowData?.admincomment
              ? typeof rowData.admincomment === "string"
                ? [rowData?.admincomment]
                : rowData?.admincomment
              : [""];

            rowDataList.push(rowData);
          }

          this.setState({ adminHistoryComment: _adminHistoryComment });

          this.props.setRowDataList("tab3", rowDataList);
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  updateEligibilityDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getNonEligibleData")
      .then((res) => {
        let data = res.data;
        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updatePaygoData = () => {
    axios
      .post(awsUrl2 + "/api/peoCampaignData/getAll", {})
      .then((res) => {
        let rowData;
        let rowDataList = [];

        let data = res.data;

        let payroll = 0,
          emod = 1;

        for (let row in data) {
          if (
            data[row].emodStatesData &&
            data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
              .rate1
          ) {
            emod =
              data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
                .rate1.value;
          }

          if (data[row].payrollData) {
            for (var state in data[row].payrollData) {
              for (var pay in data[row].payrollData[state].classCodesInfo) {
                var pyrll = numeral(
                  data[row].payrollData[state].classCodesInfo[pay].payroll.value
                ).value();

                payroll += parseInt(pyrll);
              }
            }
          }
          data[row].payroll = payroll;
          data[row].emod = emod;
        }

        this.setState({ rowDataList: data });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateDisplayTab1 = () => {
    let requestBody = {
      tab: "tab1",
      avoidIsMoreThanThousandEmp: "true",
    };
    let _engagedCommentHistory = {};
    axios
      .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
      .then((res) => {
        let userDataList = [];
        let tempObj;
        let rowData;

        let rowDataList = [];
        let domain_messageid_files = {};

        let dataFromApi = res.data.data;
        for (let key in dataFromApi) {
          tempObj = {};
          for (let dataKey in dataFromApi[key]) {
            tempObj[dataKey] = dataFromApi[key][dataKey];
          }
          userDataList.push(tempObj);
        }

        for (let userData of userDataList) {
          let quote_status;
          let step;
          let excluded_status;

          // patch code to not display entries with the following campaign names
          let incorrectCampaignNames = new Set([
            "AEMheybtb",
            "TfgDhbgf",
            "BAybtb",
            "AEybtb",
          ]);
          if (incorrectCampaignNames.has(userData?.latestCampaign)) continue;

          let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
          let secondFilter =
            userData.client_source === "Hotschedules"
              ? "Hotschedules"
              : "Affinity";

          let email_specific_status;
          let landing_email_status, landing_email_get_quote_clicked;

          for (let email in userData.email_specific) {
            let db_quote_status = userData.email_specific[email].quote_status;
            let db_excluded = userData.email_specific[email].nonEligibilityList;
            if (db_excluded && db_excluded.length > 0) {
              excluded_status = true;
            }

            if (
              userData.email_specific[email].companyProfile &&
              userData.email_specific[email].companyProfile.status &&
              userData.email_specific[email].companyProfile.status.value
            ) {
              let emailStatus =
                userData.email_specific[email].companyProfile.status.value;
              if (!email_specific_status) email_specific_status = emailStatus;
              else if (
                statusPriorityMap[emailStatus] <
                statusPriorityMap[email_specific_status]
              ) {
                email_specific_status = emailStatus;
              }
            }

            if (!landing_email_status) {
              landing_email_status = userData.email_specific[email].landingPage
                ? userData.email_specific[email].landingPage
                : null;
            }

            if (!landing_email_get_quote_clicked) {
              landing_email_get_quote_clicked = userData.email_specific[email]
                .homeGetQuote
                ? userData.email_specific[email].homeGetQuote
                : null;
            }

            if (
              (!quote_status && db_quote_status) ||
              (quote_status === "reject" && db_quote_status) ||
              db_quote_status === "proceed_uw"
            ) {
              quote_status = db_quote_status;
            }
          }

          if (userData.isSalespersonSubmitted) {
            step = "Submitted";
          } else if (userData.isUserSubmitted) {
            step = "User Submitted";
          } else if (userData.isSubmitted) {
            step = "Submitted";
          } else if (Number(userData.proposalGeneratedCount) > 0) {
            step = "Proposal Generated";
          } else if (quote_status) {
            if (quote_status === "uwportal") {
              step = "UW Portal";
            } else if (quote_status === "proceed_uw") {
              step = "Approved";
            } else if (quote_status === "reject") {
              step = "Rejected";
            }
          } else if (Number(userData.quoteGeneratedCount) > 0) {
            step = "Initial Pricing";
          } else if (userData.formStage) {
            if (userData.formStage === "one") {
              step = "Company Information";
              if (landing_email_get_quote_clicked) {
                step += " (Get Quote)";
              }
            } else {
              if (excluded_status) {
                step = "Not Eligible";
              } else {
                step = "Locations and Payroll";
              }
            }
          } else if (userData.visitCount && Number(userData.visitCount) > 0) {
            step = "Landing Page";
          } else if (landing_email_status) {
            step =
              landing_email_status === "affinityLogo"
                ? "Affinity Logo Clicked"
                : "Fourth Logo Clicked";
          } else {
            step = "-";
          }
          if (email_specific_status) {
            step =
              statusPriorityMap[email_specific_status] < statusPriorityMap[step]
                ? statusNameMapping[email_specific_status] ||
                  email_specific_status
                : statusNameMapping[step] || step;
          }

          let currProspectDetails = {
            companyProfile: userData.companyProfile,
            childrenLoc: userData.payrollData,
            emodStatesData: userData.emodStatesData,
          };

          if (userData?.brokerDetails) {
            currProspectDetails.brokerDetails = userData.brokerDetails;
          }

          rowData = {
            visitDate:
              userData?.timestamp &&
              moment(Number(userData?.timestamp))
                .utcOffset("-0500")
                .format("M/D/YY - hh:mm A"),
            userEmail: userData.email,
            quoteGeneratedCount: userData.quoteGeneratedCount || 0,
            proposalGeneratedCount: userData.proposalGeneratedCount || 0,
            uuid: userData.uuid,
            phoneNumber: userData.phoneNumber,
            payroll: userData.payroll,
            indicationCost: userData.indicationCost,
            emod:
              userData.emod && Number(userData.emod)
                ? parseFloat(userData.emod).toFixed(2)
                : "-",
            uwEmail: userData.uwEmail,
            effectiveDate: userData?.effectiveDate
              ? moment(userData?.effectiveDate).format("x")
              : "",
            contactName: userData?.contactName || "",
            companyName: userData?.companyName || "",
            ispeowith: userData?.ispeowith || "",
            campaignDate: userData?.campaignDate || "",
            special_catergory: userData?.special_catergory,
            default_saleperson: userData?.default_saleperson,
            comment: userData?.comment
              ? typeof userData?.comment === "string"
                ? [userData?.comment]
                : userData?.comment
              : [""],
            domain: userData?.domain,
            visitCount: userData?.visitCount || 0,
            step,
            latestCampaign: userData?.latestCampaign,
            email_specific: userData?.email_specific,
            messageID: userData?.messageID,
            dashboardFiles: userData?.dashboardFiles,
            preUwFiles: userData?.preUwFiles,
            fileListToStore: userData?.fileListToStore,
            zohoDate: userData?.zywave_uploaded_date
              ? moment(userData?.zywave_uploaded_date, "YYYY-MM-DD").format(
                  "M/D/YY"
                )
              : "-",
            currProspectDetails,
            salesID: userData?.domain,
            formStage: userData?.formStage,
            address: userData?.address,
            uploadedDate: userData?.uploadedDate,
            quoteData: userData?.quoteData,
            state: userData?.state || "-",
            city: userData?.city || "-",
            total_emp: userData?.total_emp || 0,
            isPreUnderwritten: userData?.preUwSaved ? true : false,
            salespersonName: this.getsalespersonName(userData)
              ? this.getsalespersonName(userData)
              : "",
            uploadedTimeStamp: userData?.uploadedTimeStamp
              ? moment(userData?.uploadedTimeStamp, "x").format("M/D/YY")
              : userData?.zywave_uploaded_date
              ? moment(userData?.zywave_uploaded_date, "YYYY-MM-DD").format(
                  "M/D/YY"
                )
              : "-",
            source: userData.source,
            salescomment: userData?.salescomment
              ? typeof userData?.salescomment === "string"
                ? [userData?.salescomment]
                : userData?.salescomment
              : [""],
            admincomment: userData?.admincomment
              ? typeof userData?.admincomment === "string"
                ? [userData?.admincomment]
                : userData?.admincomment
              : [""],
            tableName: userData.tableName,
            smcomment: userData?.smcomment
              ? typeof userData?.smcomment === "string"
                ? [userData?.smcomment]
                : userData?.smcomment
              : [""],
            dealFlag:
              userData?.dealData?.status === "true"
                ? "closed"
                : userData?.dealData?.status === "false"
                ? "lost"
                : "",
            dealFlagFilter:
              userData?.dealData?.status === "true"
                ? "won"
                : userData?.dealData?.status === "false"
                ? "lost"
                : "",
            dealConfirmed: userData?.dealConfirmed,
            recordDetails: {
              domain: userData.domain,
            },
            tab: this.props.tabName,
            Emod: userData?.Emod ? userData?.Emod : "-",
            sff: userData?.sff ? userData?.sff : "-",
          };

          console.log(userData, "1");

          createCommentForDownload(rowData, "comment");
          createCommentForDownload(rowData, "admincomment");
          createCommentForDownload(rowData, "salescomment");
          createCommentForDownload(rowData, "smcomment");

          //console.log("deal confirmed", rowData.dealConfirmed);
          if (rowData?.email_specific) {
            Object.values(rowData.email_specific).map((email) => {
              if (
                email.companyProfile &&
                email.companyProfile.contact_number &&
                email.companyProfile.contact_number.value
              ) {
                email.companyProfile.contact_number.value = this.appendRemaining(
                  email.companyProfile.contact_number.value.toString()
                );
              }
            });
          } else {
            if (rowData.phoneNumber)
              rowData.phoneNumber = this.appendRemaining(
                rowData.phoneNumber.toString()
              );
          }
          if (rowData.domain) {
            _engagedCommentHistory[rowData.domain] = rowData?.admincomment
              ? typeof rowData.admincomment === "string"
                ? [rowData?.admincomment]
                : rowData?.admincomment
              : [""];
          }

          // rowDataList[firstFilter][secondFilter].push(rowData);
          rowDataList.push(rowData);

          domain_messageid_files[rowData.domain] = {};
          if (rowData.messageID)
            domain_messageid_files[rowData.domain]["messageID"] =
              rowData.messageID;
          if (rowData.dashboardFiles)
            domain_messageid_files[rowData.domain]["dashboardFiles"] =
              rowData.dashboardFiles;
          if (rowData.preUwFiles) {
            let _preUwFiles = [];
            rowData.preUwFiles.forEach((ele) => {
              _preUwFiles.push(ele.key);
            });
            domain_messageid_files[rowData.domain]["preUwFiles"] = _preUwFiles;
          }

          if (rowData.fileListToStore) {
            let _fileListToStore = [];
            rowData.fileListToStore.forEach((ele) => {
              _fileListToStore.push(ele.key);
            });
            domain_messageid_files[rowData.domain][
              "fileListToStore"
            ] = _fileListToStore;
          }
        }

        this.setState({
          domain_messageid_files,
          engagedCommentHistory: _engagedCommentHistory,
        });
        this.props.setRowDataList("tab1", rowDataList);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateDisplayTab2 = async () => {
    let rowDataList = [];
    let domain_messageid_files = {};
    let promiseList = [];

    promiseList.push(
      this.updateDisplayTab2Engaged(rowDataList, domain_messageid_files)
    );
    promiseList.push(
      this.updateDisplayTab2Campaigned(rowDataList, domain_messageid_files)
    );

    Promise.all(promiseList).then((res) => {
      this.setState({
        domain_messageid_files,
        isLoading: false,
      });
      this.props.setRowDataList("tab2", rowDataList);
    });
  };

  updateDisplayTab2Engaged = (rowDataList, domain_messageid_files) => {
    return new Promise((resolve, reject) => {
      try {
        let _campaignedCommentsHistory = {};

        let userDataList = [];
        let tempObj1;

        axios
          .post(awsUrl2 + "/api/getDashboardTableData", {
            tab: "tab1",
            isMoreThanThousandEmp: "true",
          })
          .then((res) => {
            let tempObj;
            let rowData;
            // let rowDataList = {
            //   PEO: {
            //     Affinity: [],
            //     Hotschedules: [],
            //   },
            //   PAYGO: {
            //     Affinity: [],
            //     Hotschedules: [],
            //   },
            // };

            // let engagedDataOb = {
            //   "All": [],
            //   "HotSchedules": [],
            //   "Affinity": []
            // };

            let dataFromApi = res.data.data;
            for (let key in dataFromApi) {
              tempObj = {};
              for (let dataKey in dataFromApi[key]) {
                tempObj[dataKey] = dataFromApi[key][dataKey];
              }
              userDataList.push(tempObj);
            }

            for (let userData of userDataList) {
              let quote_status;
              let step;
              let excluded_status;

              // patch code to not display entries with the following campaign names
              let incorrectCampaignNames = new Set([
                "AEMheybtb",
                "TfgDhbgf",
                "BAybtb",
                "AEybtb",
              ]);
              if (incorrectCampaignNames.has(userData?.latestCampaign))
                continue;

              let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
              let secondFilter =
                userData.client_source === "Hotschedules"
                  ? "Hotschedules"
                  : "Affinity";

              let email_specific_status;
              let landing_email_status, landing_email_get_quote_clicked;

              for (let email in userData.email_specific) {
                // userData.fromUserTracking = true;
                let db_quote_status =
                  userData.email_specific[email].quote_status;
                let db_excluded =
                  userData.email_specific[email].nonEligibilityList;
                if (db_excluded && db_excluded.length > 0) {
                  excluded_status = true;
                }

                if (
                  userData.email_specific[email].companyProfile &&
                  userData.email_specific[email].companyProfile.status &&
                  userData.email_specific[email].companyProfile.status.value
                ) {
                  let emailStatus =
                    userData.email_specific[email].companyProfile.status.value;
                  if (!email_specific_status)
                    email_specific_status = emailStatus;
                  else if (
                    statusPriorityMap[emailStatus] <
                    statusPriorityMap[email_specific_status]
                  ) {
                    email_specific_status = emailStatus;
                  }
                }
                if (!landing_email_status) {
                  landing_email_status = userData.email_specific[email]
                    .landingPage
                    ? userData.email_specific[email].landingPage
                    : null;
                }

                if (!landing_email_get_quote_clicked) {
                  landing_email_get_quote_clicked = userData.email_specific[
                    email
                  ].homeGetQuote
                    ? userData.email_specific[email].homeGetQuote
                    : null;
                }

                if (
                  (!quote_status && db_quote_status) ||
                  (quote_status === "reject" && db_quote_status) ||
                  db_quote_status === "proceed_uw"
                ) {
                  quote_status = db_quote_status;
                }
              }

              if (userData.isSalespersonSubmitted) {
                step = "Submitted";
              } else if (userData.isUserSubmitted) {
                step = "User Submitted";
              } else if (userData.isSubmitted) {
                step = "Submitted";
              } else if (Number(userData.proposalGeneratedCount) > 0) {
                step = "Proposal Generated";
              } else if (quote_status) {
                if (quote_status === "uwportal") {
                  step = "UW Portal";
                } else if (quote_status === "proceed_uw") {
                  step = "Approved";
                } else if (quote_status === "reject") {
                  step = "Rejected";
                }
              } else if (Number(userData.quoteGeneratedCount) > 0) {
                step = "Initial Pricing";
              } else if (userData.formStage) {
                if (userData.formStage === "one") {
                  step = "Company Information";
                  if (landing_email_get_quote_clicked) {
                    step += " (Get Quote)";
                  }
                } else {
                  if (excluded_status) {
                    step = "Not Eligible";
                  } else {
                    step = "Locations and Payroll";
                  }
                }
              } else if (
                userData.visitCount &&
                Number(userData.visitCount) > 0
              ) {
                step = "Landing Page";
              } else if (landing_email_status) {
                step =
                  landing_email_status === "affinityLogo"
                    ? "Affinity Logo Clicked"
                    : "Fourth Logo Clicked";
              } else {
                step = "-";
              }
              if (email_specific_status) {
                step =
                  statusPriorityMap[email_specific_status] <
                  statusPriorityMap[step]
                    ? statusNameMapping[email_specific_status] ||
                      email_specific_status
                    : statusNameMapping[step] || step;
              }

              let currProspectDetails = {
                companyProfile: userData.companyProfile,
                childrenLoc: userData.payrollData,
                emodStatesData: userData.emodStatesData,
                // companyProfileAddQuestions: userData.companyProfileAddQuestions,
                // uwQues: userData.uwQues,
                // requestedPricing: userData.requestedPricing
              };

              if (userData?.brokerDetails) {
                currProspectDetails.brokerDetails = userData.brokerDetails;
              }
              // let total_emp = 0;
              // if (userData.payrollData) {
              //   let childrenLoc = userData.payrollData;
              //   for (let locIndex in childrenLoc) {
              //     let loc = childrenLoc[locIndex];
              //     for (let ccIndex in loc.classCodesInfo) {
              //       let cc = loc.classCodesInfo[ccIndex];
              //       total_emp =
              //         total_emp +
              //         Number(cc?.ft?.value || 0) +
              //         Number(cc?.pt?.value || 0);
              //     }
              //   }
              // }
              rowData = {
                visitDate:
                  userData.timestamp &&
                  moment(Number(userData.timestamp))
                    .utcOffset("-0500")
                    .format("M/D/YY - hh:mm A"),
                userEmail: userData.email,
                quoteGeneratedCount: userData.quoteGeneratedCount || 0,
                proposalGeneratedCount: userData.proposalGeneratedCount || 0,
                uuid: userData.uuid,
                phoneNumber: userData.phoneNumber,
                payroll: userData.payroll,
                indicationCost: userData.indicationCost,
                emod:
                  userData.emod && Number(userData.emod)
                    ? parseFloat(userData.emod).toFixed(2)
                    : "-",
                uwEmail: userData.uwEmail,
                effectiveDate: userData.effectiveDate
                  ? moment(userData.effectiveDate).format("x")
                  : "",
                contactName: userData.contactName || "",
                companyName: userData.companyName || "",
                ispeowith: userData?.ispeowith || "",
                campaignDate: userData?.campaignDate || "",
                special_catergory: userData?.special_catergory,
                default_saleperson: userData?.default_saleperson,
                comment: userData?.comment
                  ? typeof userData?.comment === "string"
                    ? [userData?.comment]
                    : userData?.comment
                  : [""],
                domain: userData.domain,
                visitCount: userData.visitCount || 0,
                step,
                latestCampaign: userData.latestCampaign,
                email_specific: userData.email_specific,
                messageID: userData.messageID,
                dashboardFiles: userData.dashboardFiles,
                zohoDate: userData.zywave_uploaded_date
                  ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                      "M/D/YY"
                    )
                  : "-",
                currProspectDetails,
                salesID: userData.domain,
                formStage: userData.formStage,
                address: userData.address,
                quoteData: userData.quoteData,
                uploadedDate: userData.uploadedDate,
                state: userData?.state || "-",
                city: userData?.city || "-",
                total_emp: userData?.total_emp || 0,
                salespersonName: this.getsalespersonName(userData)
                  ? this.getsalespersonName(userData)
                  : "",
                uploadedTimeStamp: userData.uploadedTimeStamp
                  ? moment(userData.uploadedTimeStamp, "x").format("M/D/YY")
                  : userData.zywave_uploaded_date
                  ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                      "M/D/YY"
                    )
                  : "-",
                source: userData.source,
                isPreUnderwritten: userData.preUwSaved ? true : false,
                salescomment: userData?.salescomment
                  ? typeof userData?.salescomment === "string"
                    ? [userData?.salescomment]
                    : userData?.salescomment
                  : [""],
                admincomment: userData?.admincomment
                  ? typeof userData?.admincomment === "string"
                    ? [userData?.admincomment]
                    : userData?.admincomment
                  : [""],
                tableName: userData.tableName,
                smcomment: userData?.smcomment
                  ? typeof userData?.smcomment === "string"
                    ? [userData?.smcomment]
                    : userData?.smcomment
                  : [""],
                dealFlag:
                  userData?.dealData?.status === "true"
                    ? "closed"
                    : userData?.dealData?.status === "false"
                    ? "lost"
                    : "",
                dealFlagFilter:
                  userData?.dealData?.status === "true"
                    ? "won"
                    : userData?.dealData?.status === "false"
                    ? "lost"
                    : "",
                dealConfirmed: userData?.dealConfirmed,
                recordDetails: {
                  domain: userData.domain,
                  isMailCmpn:
                    userData.tableName === "campaignTable" ? true : false,
                },
                tab: this.props.tabName,
                fromUserTracking: true,
                Emod: userData?.Emod ? userData?.Emod : "-",
                sff: userData?.sff ? userData?.sff : "-",
              };

              createCommentForDownload(rowData, "comment");
              createCommentForDownload(rowData, "admincomment");
              createCommentForDownload(rowData, "salescomment");
              createCommentForDownload(rowData, "smcomment");

              if (rowData.email_specific) {
                Object.values(rowData.email_specific).map((email) => {
                  if (
                    email.companyProfile &&
                    email.companyProfile.contact_number &&
                    email.companyProfile.contact_number.value
                  ) {
                    email.companyProfile.contact_number.value = this.appendRemaining(
                      email.companyProfile.contact_number.value.toString()
                    );
                  }
                });
              } else {
                if (rowData.phoneNumber)
                  rowData.phoneNumber = this.appendRemaining(
                    rowData.phoneNumber.toString()
                  );
              }

              if (rowData.domain) {
                _campaignedCommentsHistory[
                  rowData.domain
                ] = rowData?.admincomment
                  ? typeof rowData.admincomment === "string"
                    ? [rowData?.admincomment]
                    : rowData?.admincomment
                  : [""];
              }

              // rowDataList[firstFilter][secondFilter].push(rowData);
              rowDataList.push(rowData);
              // engagedDataOb.All.push(rowData);

              // if (rowData.source === "affinity") {
              //   engagedDataOb.Affinity.push(rowData);
              // } else if (rowData.source === "hotschedules") {
              //   engagedDataOb.HotSchedules.push(rowData);
              // }

              domain_messageid_files[rowData.domain] = {};
              if (rowData.messageID)
                domain_messageid_files[rowData.domain]["messageID"] =
                  rowData.messageID;
              if (rowData.dashboardFiles)
                domain_messageid_files[rowData.domain]["dashboardFiles"] =
                  rowData.dashboardFiles;
              if (userData.preUwFiles) {
                let _preUwFiles = [];
                userData.preUwFiles.forEach((ele) => {
                  _preUwFiles.push(ele.key);
                });
                domain_messageid_files[userData.domain][
                  "preUwFiles"
                ] = _preUwFiles;
              }

              if (userData.fileListToStore) {
                let _fileListToStore = [];
                userData.fileListToStore.forEach((ele) => {
                  _fileListToStore.push(ele.key);
                });
                domain_messageid_files[userData.domain][
                  "fileListToStore"
                ] = _fileListToStore;
              }
            }

            resolve();
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            // this.setState({ isLoading: false });
          });
      } catch (error) {
        resolve();
      }
    });
  };

  updateDisplayTab2Campaigned = (rowDataList, domain_messageid_files) => {
    return new Promise((resolve, reject) => {
      try {
        let _campaignedCommentsHistory = {};

        let userDataList = [];
        let tempObj1;

        axios
          .post(awsUrl2 + "/api/preUwData/getAllRows", {
            isMoreThanThousandEmp: "true",
          })
          .then((res) => {
            let tempObj;
            let rowData;

            let dataFromApi = res.data;
            for (let key in dataFromApi) {
              tempObj = {};
              for (let dataKey in dataFromApi[key]) {
                tempObj[dataKey] = dataFromApi[key][dataKey];
              }
              userDataList.push(tempObj);
            }

            for (let userData of userDataList) {
              let quote_status;
              let step;
              let excluded_status;

              // patch code to not display entries with the following campaign names
              let incorrectCampaignNames = new Set([
                "AEMheybtb",
                "TfgDhbgf",
                "BAybtb",
                "AEybtb",
              ]);
              if (incorrectCampaignNames.has(userData?.latestCampaign))
                continue;

              let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
              let secondFilter =
                userData.client_source === "Hotschedules"
                  ? "Hotschedules"
                  : "Affinity";

              let email_specific_status;
              let landing_email_status, landing_email_get_quote_clicked;

              for (let email in userData.email_specific) {
                let db_quote_status =
                  userData.email_specific[email].quote_status;
                let db_excluded =
                  userData.email_specific[email].nonEligibilityList;
                if (db_excluded && db_excluded.length > 0) {
                  excluded_status = true;
                }

                if (
                  userData.email_specific[email].companyProfile &&
                  userData.email_specific[email].companyProfile.status &&
                  userData.email_specific[email].companyProfile.status.value
                ) {
                  let emailStatus =
                    userData.email_specific[email].companyProfile.status.value;
                  if (!email_specific_status)
                    email_specific_status = emailStatus;
                  else if (
                    statusPriorityMap[emailStatus] <
                    statusPriorityMap[email_specific_status]
                  ) {
                    email_specific_status = emailStatus;
                  }
                }
                if (!landing_email_status) {
                  landing_email_status = userData.email_specific[email]
                    .landingPage
                    ? userData.email_specific[email].landingPage
                    : null;
                }

                if (!landing_email_get_quote_clicked) {
                  landing_email_get_quote_clicked = userData.email_specific[
                    email
                  ].homeGetQuote
                    ? userData.email_specific[email].homeGetQuote
                    : null;
                }

                if (
                  (!quote_status && db_quote_status) ||
                  (quote_status === "reject" && db_quote_status) ||
                  db_quote_status === "proceed_uw"
                ) {
                  quote_status = db_quote_status;
                }
              }

              if (userData.isSalespersonSubmitted) {
                step = "Submitted";
              } else if (userData.isUserSubmitted) {
                step = "User Submitted";
              } else if (userData.isSubmitted) {
                step = "Submitted";
              } else if (Number(userData.proposalGeneratedCount) > 0) {
                step = "Proposal Generated";
              } else if (quote_status) {
                if (quote_status === "uwportal") {
                  step = "UW Portal";
                } else if (quote_status === "proceed_uw") {
                  step = "Approved";
                } else if (quote_status === "reject") {
                  step = "Rejected";
                }
              } else if (Number(userData.quoteGeneratedCount) > 0) {
                step = "Initial Pricing";
              } else if (userData.formStage) {
                if (userData.formStage === "one") {
                  step = "Company Information";
                  if (landing_email_get_quote_clicked) {
                    step += " (Get Quote)";
                  }
                } else {
                  if (excluded_status) {
                    step = "Not Eligible";
                  } else {
                    step = "Locations and Payroll";
                  }
                }
              } else if (
                userData.visitCount &&
                Number(userData.visitCount) > 0
              ) {
                step = "Landing Page";
              } else if (landing_email_status) {
                step =
                  landing_email_status === "affinityLogo"
                    ? "Affinity Logo Clicked"
                    : "Fourth Logo Clicked";
              } else {
                step = "-";
              }
              if (email_specific_status) {
                step =
                  statusPriorityMap[email_specific_status] <
                  statusPriorityMap[step]
                    ? email_specific_status
                    : step;
              }

              let currProspectDetails = {
                companyProfile: userData.companyProfile,
                childrenLoc: userData.payrollData,
                emodStatesData: userData.emodStatesData,
                // companyProfileAddQuestions: userData.companyProfileAddQuestions,
                // uwQues: userData.uwQues,
                // requestedPricing: userData.requestedPricing
              };

              if (userData?.brokerDetails) {
                currProspectDetails.brokerDetails = userData.brokerDetails;
              }
              // let total_emp = 0;
              // if (userData.payrollData) {
              //   let childrenLoc = userData.payrollData;
              //   for (let locIndex in childrenLoc) {
              //     let loc = childrenLoc[locIndex];
              //     for (let ccIndex in loc.classCodesInfo) {
              //       let cc = loc.classCodesInfo[ccIndex];
              //       total_emp =
              //         total_emp +
              //         Number(cc?.ft?.value || 0) +
              //         Number(cc?.pt?.value || 0);
              //     }
              //   }
              // }
              rowData = {
                visitDate:
                  userData.timestamp &&
                  moment(Number(userData.timestamp))
                    .utcOffset("-0500")
                    .format("M/D/YY - hh:mm A"),
                userEmail: userData.email,
                quoteGeneratedCount: userData.quoteGeneratedCount || 0,
                proposalGeneratedCount: userData.proposalGeneratedCount || 0,
                uuid: userData.uuid,
                phoneNumber: userData.phoneNumber,
                payroll: userData.payroll,
                indicationCost: userData.indicationCost,
                emod:
                  userData.emod && Number(userData.emod)
                    ? parseFloat(userData.emod).toFixed(2)
                    : "-",
                uwEmail: userData.uwEmail,
                effectiveDate: userData.effectiveDate
                  ? moment(userData.effectiveDate).format("x")
                  : "",
                contactName: userData.contactName || "",
                companyName: userData.companyName || "",
                ispeowith: userData?.ispeowith || "",
                campaignDate: userData?.campaignDate || "",
                special_catergory: userData?.special_catergory,
                default_saleperson: userData?.default_saleperson,
                comment: userData?.comment
                  ? typeof userData?.comment === "string"
                    ? [userData?.comment]
                    : userData?.comment
                  : [""],
                domain: userData.domain,
                visitCount: userData.visitCount || 0,
                step,
                latestCampaign: userData.latestCampaign,
                email_specific: userData.email_specific,
                messageID: userData.messageID,
                dashboardFiles: userData.dashboardFiles,
                zohoDate: userData.zywave_uploaded_date
                  ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                      "M/D/YY"
                    )
                  : "-",
                currProspectDetails,
                salesID: userData.domain,
                formStage: userData.formStage,
                address: userData.address,
                quoteData: userData.quoteData,
                uploadedDate: userData.uploadedDate,
                state: userData?.state || "-",
                city: userData?.city || "-",
                total_emp: userData?.total_emp || 0,
                salespersonName: this.getsalespersonName(userData)
                  ? this.getsalespersonName(userData)
                  : "",
                uploadedTimeStamp: userData.uploadedTimeStamp
                  ? moment(userData.uploadedTimeStamp, "x").format("M/D/YY")
                  : userData.zywave_uploaded_date
                  ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                      "M/D/YY"
                    )
                  : "-",
                source: userData.source,
                isPreUnderwritten: userData.preUwSaved ? true : false,
                salescomment: userData?.salescomment
                  ? typeof userData?.salescomment === "string"
                    ? [userData?.salescomment]
                    : userData?.salescomment
                  : [""],
                admincomment: userData?.admincomment
                  ? typeof userData?.admincomment === "string"
                    ? [userData?.admincomment]
                    : userData?.admincomment
                  : [""],
                tableName: userData.tableName,
                smcomment: userData?.smcomment
                  ? typeof userData?.smcomment === "string"
                    ? [userData?.smcomment]
                    : userData?.smcomment
                  : [""],
                dealFlag:
                  userData?.dealData?.status === "true"
                    ? "closed"
                    : userData?.dealData?.status === "false"
                    ? "lost"
                    : "",
                dealFlagFilter:
                  userData?.dealData?.status === "true"
                    ? "won"
                    : userData?.dealData?.status === "false"
                    ? "lost"
                    : "",
                dealConfirmed: userData?.dealConfirmed,
                recordDetails: {
                  domain: userData.domain,
                  isMailCmpn:
                    userData.tableName === "campaignTable" ? true : false,
                },
                tab: this.props.tabName,
                fromUserTracking: false,
                Emod: userData?.Emod ? userData?.Emod : "-",
                sff: userData?.sff ? userData?.sff : "-",
              };
              console.log(userData);

              createCommentForDownload(rowData, "comment");
              createCommentForDownload(rowData, "admincomment");
              createCommentForDownload(rowData, "salescomment");
              createCommentForDownload(rowData, "smcomment");

              if (rowData.email_specific) {
                Object.values(rowData.email_specific).map((email) => {
                  if (
                    email.companyProfile &&
                    email.companyProfile.contact_number &&
                    email.companyProfile.contact_number.value
                  ) {
                    email.companyProfile.contact_number.value = this.appendRemaining(
                      email.companyProfile.contact_number.value.toString()
                    );
                  }
                });
              } else {
                if (rowData.phoneNumber)
                  rowData.phoneNumber = this.appendRemaining(
                    rowData.phoneNumber.toString()
                  );
              }

              if (rowData.domain) {
                _campaignedCommentsHistory[
                  rowData.domain
                ] = rowData?.admincomment
                  ? typeof rowData.admincomment === "string"
                    ? [rowData?.admincomment]
                    : rowData?.admincomment
                  : [""];
              }

              // rowDataList[firstFilter][secondFilter].push(rowData);
              rowDataList.push(rowData);
              // engagedDataOb.All.push(rowData);

              // if (rowData.source === "affinity") {
              //   engagedDataOb.Affinity.push(rowData);
              // } else if (rowData.source === "hotschedules") {
              //   engagedDataOb.HotSchedules.push(rowData);
              // }

              domain_messageid_files[rowData.domain] = {};
              if (rowData.messageID)
                domain_messageid_files[rowData.domain]["messageID"] =
                  rowData.messageID;
              if (rowData.dashboardFiles)
                domain_messageid_files[rowData.domain]["dashboardFiles"] =
                  rowData.dashboardFiles;
              if (userData.preUwFiles) {
                let _preUwFiles = [];
                userData.preUwFiles.forEach((ele) => {
                  _preUwFiles.push(ele.key);
                });
                domain_messageid_files[userData.domain][
                  "preUwFiles"
                ] = _preUwFiles;
              }

              if (userData.fileListToStore) {
                let _fileListToStore = [];
                userData.fileListToStore.forEach((ele) => {
                  _fileListToStore.push(ele.key);
                });
                domain_messageid_files[userData.domain][
                  "fileListToStore"
                ] = _fileListToStore;
              }
            }
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            // this.setState({ isLoading: false });
            resolve();
          });
      } catch (error) {
        resolve();
      }
    });
  };

  updateDisplayTab3 = async () => {
    let rowDataList = [];
    let domain_messageid_files = {};
    let promiseList = [];

    // promiseList.push(updateDisplayTab2Engaged(rowDataList, domain_messageid_files));
    promiseList.push(
      this.updateDisplayTab3Campaigned(rowDataList, domain_messageid_files)
    );

    Promise.all(promiseList).then((res) => {
      this.setState({
        domain_messageid_files,
        isLoading: false,
      });
      this.props.setRowDataList("tab3", rowDataList);
    });
  };

  updateDisplayTab3Campaigned = (rowDataList, domain_messageid_files) => {
    return new Promise((resolve, reject) => {
      try {
        let _campaignedCommentsHistory = {};

        let userDataList = [];
        let tempObj1;

        axios
          .post(awsUrl2 + "/api/preUwData/getAllRows", {
            avoidIsMoreThanThousandEmp: "true",
          })
          .then((res) => {
            let tempObj;
            let rowData;

            let dataFromApi = res.data;
            for (let key in dataFromApi) {
              tempObj = {};
              for (let dataKey in dataFromApi[key]) {
                tempObj[dataKey] = dataFromApi[key][dataKey];
              }
              userDataList.push(tempObj);
            }

            for (let userData of userDataList) {
              let quote_status;
              let step;
              let excluded_status;

              // patch code to not display entries with the following campaign names
              let incorrectCampaignNames = new Set([
                "AEMheybtb",
                "TfgDhbgf",
                "BAybtb",
                "AEybtb",
              ]);
              if (incorrectCampaignNames.has(userData?.latestCampaign))
                continue;

              let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
              let secondFilter =
                userData.client_source === "Hotschedules"
                  ? "Hotschedules"
                  : "Affinity";

              let email_specific_status;
              let landing_email_status, landing_email_get_quote_clicked;

              for (let email in userData.email_specific) {
                let db_quote_status =
                  userData.email_specific[email].quote_status;
                let db_excluded =
                  userData.email_specific[email].nonEligibilityList;
                if (db_excluded && db_excluded.length > 0) {
                  excluded_status = true;
                }

                if (
                  userData.email_specific[email].companyProfile &&
                  userData.email_specific[email].companyProfile.status &&
                  userData.email_specific[email].companyProfile.status.value
                ) {
                  let emailStatus =
                    userData.email_specific[email].companyProfile.status.value;
                  if (!email_specific_status)
                    email_specific_status = emailStatus;
                  else if (
                    statusPriorityMap[emailStatus] <
                    statusPriorityMap[email_specific_status]
                  ) {
                    email_specific_status = emailStatus;
                  }
                }
                if (!landing_email_status) {
                  landing_email_status = userData.email_specific[email]
                    .landingPage
                    ? userData.email_specific[email].landingPage
                    : null;
                }

                if (!landing_email_get_quote_clicked) {
                  landing_email_get_quote_clicked = userData.email_specific[
                    email
                  ].homeGetQuote
                    ? userData.email_specific[email].homeGetQuote
                    : null;
                }

                if (
                  (!quote_status && db_quote_status) ||
                  (quote_status === "reject" && db_quote_status) ||
                  db_quote_status === "proceed_uw"
                ) {
                  quote_status = db_quote_status;
                }
              }

              if (userData.isSalespersonSubmitted) {
                step = "Submitted";
              } else if (userData.isUserSubmitted) {
                step = "User Submitted";
              } else if (userData.isSubmitted) {
                step = "Submitted";
              } else if (Number(userData.proposalGeneratedCount) > 0) {
                step = "Proposal Generated";
              } else if (quote_status) {
                if (quote_status === "uwportal") {
                  step = "UW Portal";
                } else if (quote_status === "proceed_uw") {
                  step = "Approved";
                } else if (quote_status === "reject") {
                  step = "Rejected";
                }
              } else if (Number(userData.quoteGeneratedCount) > 0) {
                step = "Initial Pricing";
              } else if (userData.formStage) {
                if (userData.formStage === "one") {
                  step = "Company Information";
                  if (landing_email_get_quote_clicked) {
                    step += " (Get Quote)";
                  }
                } else {
                  if (excluded_status) {
                    step = "Not Eligible";
                  } else {
                    step = "Locations and Payroll";
                  }
                }
              } else if (
                userData.visitCount &&
                Number(userData.visitCount) > 0
              ) {
                step = "Landing Page";
              } else if (landing_email_status) {
                step =
                  landing_email_status === "affinityLogo"
                    ? "Affinity Logo Clicked"
                    : "Fourth Logo Clicked";
              } else {
                step = "-";
              }
              if (email_specific_status) {
                step =
                  statusPriorityMap[email_specific_status] <
                  statusPriorityMap[step]
                    ? email_specific_status
                    : step;
              }

              let currProspectDetails = {
                companyProfile: userData.companyProfile,
                childrenLoc: userData.payrollData,
                emodStatesData: userData.emodStatesData,
                // companyProfileAddQuestions: userData.companyProfileAddQuestions,
                // uwQues: userData.uwQues,
                // requestedPricing: userData.requestedPricing
              };

              if (userData?.brokerDetails) {
                currProspectDetails.brokerDetails = userData.brokerDetails;
              }

              rowData = {
                visitDate:
                  userData.timestamp &&
                  moment(Number(userData.timestamp))
                    .utcOffset("-0500")
                    .format("M/D/YY - hh:mm A"),
                userEmail: userData.email,
                quoteGeneratedCount: userData.quoteGeneratedCount || 0,
                proposalGeneratedCount: userData.proposalGeneratedCount || 0,
                uuid: userData.uuid,
                phoneNumber: userData.phoneNumber,
                payroll: userData.payroll,
                indicationCost: userData.indicationCost,
                emod:
                  userData.emod && Number(userData.emod)
                    ? parseFloat(userData.emod).toFixed(2)
                    : "-",
                uwEmail: userData.uwEmail,
                effectiveDate: userData.effectiveDate
                  ? moment(userData.effectiveDate).format("x")
                  : "",
                contactName: userData.contactName || "",
                companyName: userData.companyName || "",
                ispeowith: userData?.ispeowith || "",
                campaignDate: userData?.campaignDate || "",
                special_catergory: userData?.special_catergory,
                default_saleperson: userData?.default_saleperson,
                comment: userData?.comment
                  ? typeof userData?.comment === "string"
                    ? [userData?.comment]
                    : userData?.comment
                  : [""],
                domain: userData.domain,
                visitCount: userData.visitCount || 0,
                step,
                latestCampaign: userData.latestCampaign,
                email_specific: userData.email_specific,
                messageID: userData.messageID,
                dashboardFiles: userData.dashboardFiles,
                zohoDate: userData.zywave_uploaded_date
                  ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                      "M/D/YY"
                    )
                  : "-",
                currProspectDetails,
                salesID: userData.domain,
                formStage: userData.formStage,
                address: userData.address,
                quoteData: userData.quoteData,
                uploadedDate: userData.uploadedDate,
                state: userData?.state || "-",
                city: userData?.city || "-",
                total_emp: userData?.total_emp || 0,
                salespersonName: this.getsalespersonName(userData)
                  ? this.getsalespersonName(userData)
                  : "",
                uploadedTimeStamp: userData.uploadedTimeStamp
                  ? moment(userData.uploadedTimeStamp, "x").format("M/D/YY")
                  : userData.zywave_uploaded_date
                  ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                      "M/D/YY"
                    )
                  : "-",
                source: userData.source,
                isPreUnderwritten: userData.preUwSaved ? true : false,
                salescomment: userData?.salescomment
                  ? typeof userData?.salescomment === "string"
                    ? [userData?.salescomment]
                    : userData?.salescomment
                  : [""],
                admincomment: userData?.admincomment
                  ? typeof userData?.admincomment === "string"
                    ? [userData?.admincomment]
                    : userData?.admincomment
                  : [""],
                tableName: userData.tableName,
                smcomment: userData?.smcomment
                  ? typeof userData?.smcomment === "string"
                    ? [userData?.smcomment]
                    : userData?.smcomment
                  : [""],
                dealFlag:
                  userData?.dealData?.status === "true"
                    ? "closed"
                    : userData?.dealData?.status === "false"
                    ? "lost"
                    : "",
                dealFlagFilter:
                  userData?.dealData?.status === "true"
                    ? "won"
                    : userData?.dealData?.status === "false"
                    ? "lost"
                    : "",
                dealConfirmed: userData?.dealConfirmed,
                recordDetails: {
                  domain: userData.domain,
                  isMailCmpn:
                    userData.tableName === "campaignTable" ? true : false,
                },
                tab: this.props.tabName,
                Emod: userData?.Emod ? userData?.Emod : "-",
                sff: userData?.sff ? userData?.sff : "-",
              };

              createCommentForDownload(rowData, "comment");
              createCommentForDownload(rowData, "admincomment");
              createCommentForDownload(rowData, "salescomment");
              createCommentForDownload(rowData, "smcomment");

              if (rowData.email_specific) {
                Object.values(rowData.email_specific).map((email) => {
                  if (
                    email.companyProfile &&
                    email.companyProfile.contact_number &&
                    email.companyProfile.contact_number.value
                  ) {
                    email.companyProfile.contact_number.value = this.appendRemaining(
                      email.companyProfile.contact_number.value.toString()
                    );
                  }
                });
              } else {
                if (rowData.phoneNumber)
                  rowData.phoneNumber = this.appendRemaining(
                    rowData.phoneNumber.toString()
                  );
              }

              if (rowData.domain) {
                _campaignedCommentsHistory[
                  rowData.domain
                ] = rowData?.admincomment
                  ? typeof rowData.admincomment === "string"
                    ? [rowData?.admincomment]
                    : rowData?.admincomment
                  : [""];
              }

              rowDataList.push(rowData);

              domain_messageid_files[rowData.domain] = {};
              if (rowData.messageID)
                domain_messageid_files[rowData.domain]["messageID"] =
                  rowData.messageID;
              if (rowData.dashboardFiles)
                domain_messageid_files[rowData.domain]["dashboardFiles"] =
                  rowData.dashboardFiles;
              if (userData.preUwFiles) {
                let _preUwFiles = [];
                userData.preUwFiles.forEach((ele) => {
                  _preUwFiles.push(ele.key);
                });
                domain_messageid_files[userData.domain][
                  "preUwFiles"
                ] = _preUwFiles;
              }

              if (userData.fileListToStore) {
                let _fileListToStore = [];
                userData.fileListToStore.forEach((ele) => {
                  _fileListToStore.push(ele.key);
                });
                domain_messageid_files[userData.domain][
                  "fileListToStore"
                ] = _fileListToStore;
              }
            }
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            resolve();
          });
      } catch (error) {
        resolve();
      }
    });
  };

  render() {
    let { selected, tabName } = this.props;
    // console.log("rowDataListrowDataList", this.props.rowDataList);
    let {
      showModel,
      modalTitle,
      modalContent,
      sourceSelected,
      popupData,
      openModal,
      modalBody,
      style,
    } = this.state;
    // console.log("sourceSelected: ", sourceSelected, " tabName: ", tabName);
    return (
      <>
        {selected === tabName ? (
          <div className={"admin-dashboard " + `${tabName}`} style={style}>
            {/* {tabName === "tab1" && (
              <div className="mt-3 mb-3 dashboard-dropdown-row">
                <select
                  value={sourceSelected}
                  onChange={(e) => {
                    this.setState({ sourceSelected: e.target.value });
                  }}
                >
                  <option value={"All"}>All</option>
                  <option value={"HotSchedules"}>Hot Schedules</option>
                  <option value={"Affinity"}>Affinity</option>
                </select>
              </div>
            )} */}
            <div ref={this.myRef}>{this.setTableData()}</div>
            <Modal
              show={showModel}
              onHide={() => {
                this.setState({
                  showModel: false,
                  modalTitle: null,
                  modalContent: null,
                });
              }}
              dialogClassName="model-download modal-dialog-centered"
            >
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalContent}</Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}
        <Toaster />
        <Dialog open={openModal} onClose={this.handleCloseModal}>
          <DialogTitle>
            <p style={{ textAlign: "center" }}>
              {(modalBody.length > 0 &&
                modalBody?.[0] !== "" &&
                modalBody?.[0]?.comment !== "") ||
              modalBody?.[0]?.previousComment
                ? "Comment History"
                : "No Comment History"}
            </p>
            <IconButton
              aria-label="close"
              onClick={this.handleCloseModal}
              style={{ position: "absolute", right: "5px", top: "8px" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {modalBody.length > 0 &&
              modalBody[0] !== "" &&
              modalBody.map((item, index) => {
                let date = moment(item.commentTimestamp)
                  .format("M/D/YY h:mm A")
                  .split(" ")[0];
                let time = moment(item.commentTimestamp)
                  .format("M/D/YY h:mm A")
                  .split(" ")[1];
                if (item.comment !== "") {
                  if (item.commentTimestamp || item.email || item) {
                    return (
                      <div>
                        <div>
                          <span>
                            <b>{date}</b>
                          </span>
                          <span
                            style={{
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <b>{time}</b>
                          </span>
                          <span>{item.email ? "- " : ""}</span>
                          <span>
                            <b>{item.email?.split("@")[0]}</b>
                          </span>
                        </div>
                        <div>
                          <p>
                            {item?.comment
                              ? item.comment
                              : modalBody[index + 1]?.comment
                              ? modalBody[index + 1].comment
                              : item}
                          </p>
                        </div>
                        <br />
                      </div>
                    );
                  }
                } else {
                  return;
                }
              })}
          </DialogContent>
        </Dialog>
        {popupData.show && (
          <Popup popupData={popupData} updatePopUpData={this.updatePopUpData} />
        )}
      </>
    );
  }
}

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      loggedInEmail: "",
    };
  }
  onChangeHandler(target) {
    this.setState({ value: target });
  }

  componentDidMount() {
    this.loggedInEmailId();
  }

  loggedInEmailId = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      this.setState({ loggedInEmail: sessionData["attributes"]["email"] });
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
    }
  };

  render() {
    let { value, loggedInEmail } = this.state;
    const checkcommentAvailble = (comment) => {
      if (comment?.[0] !== "") {
        if (typeof comment?.[0] === "string") {
          return "#FFE7C1";
        }
      }
      if (comment?.length > 1) {
        return "#FFE7C1";
      }
    };

    return (
      <textarea
        value={value}
        className="underwriter_comment"
        rows="1"
        style={{
          width: "100%",
          borderRadius: "4px",
          backgroundColor: checkcommentAvailble(this.props.comment),
        }}
        onChange={(e) => this.onChangeHandler(e.target.value)}
        onBlur={(e) => {
          let adminCommentsCopy = this.props.comment;
          // console.log(this.props.comment)
          let currentTimestamp = moment().valueOf();
          let dataToSave = {};

          if (adminCommentsCopy === undefined && e.target.value.trim() !== "") {
            adminCommentsCopy = [
              {
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              },
            ];
          } else if (
            e.target.value.length === 0 ||
            e.target.value.trim() === ""
          ) {
            if (
              adminCommentsCopy[0]?.comment &&
              typeof adminCommentsCopy[0] === "object"
            ) {
              e.target.value.trim() !== adminCommentsCopy[0]?.comment &&
                adminCommentsCopy.unshift({
                  comment: e.target.value.trim(),
                  commentTimestamp: currentTimestamp,
                  previousComment: "true",
                  email: loggedInEmail,
                });
            } else if (
              adminCommentsCopy[0] &&
              typeof adminCommentsCopy[0] === "string"
            ) {
              e.target.value.trim() !== adminCommentsCopy[0] &&
                adminCommentsCopy.unshift({
                  comment: e.target.value.trim(),
                  commentTimestamp: currentTimestamp,
                  previousComment: "true",
                  email: loggedInEmail,
                });
            } else {
              console.log("I m returning as value is 0");
              return;
            }
          } else if (typeof adminCommentsCopy[0] === "string") {
            e.target.value.trim() !== adminCommentsCopy[0] &&
              adminCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              });
          } else {
            e.target.value.trim() !== adminCommentsCopy[0].comment &&
              adminCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              });
          }

          // this.props.handleAgentCommentHistory(
          //   this.props.rowData,
          //   agentCommentsCopy
          // );
          if (this.props.rowDataSortKeyList) {
            dataToSave.sortKeyList = this.props.rowDataSortKeyList;
          }

          this.props.UpdateCommentsHistoryState(
            this.props.rowDataID,
            adminCommentsCopy,
            this.props.rowData
          );

          dataToSave[this.props.rowDataName] = this.props.rowDataID;
          dataToSave[this.props.rowDataComments] = adminCommentsCopy;
          dataToSave[this.props.rowDataComments] &&
            this.props.callback(dataToSave, this.props.rowData);
        }}
      ></textarea>
    );
  }
}
export default DashboardAdmin;
